import React, {useEffect} from 'react';
import ServicesBanner from '../../UI/ServicesBanner';
import StuffBackgroundTest from '../../../assets/img/StuffBackgroundTest.jpg';
import SectionWrapper from '../../UI/SectionWrapper';
import {useLocation} from 'react-router-dom';
import servicesData from '../../../DB/services-details.json';
import {Box} from "@material-ui/core";
import Sidebar from "../SideBarStatic";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ServicesButton from "../ServicesButton";
import FeedbackDB from "../../../DB/feedback.json";
import Grid from "@material-ui/core/Grid";
import ReviewCard from "../FeedbackCard";
import FeedbackInTotalCard from "../FeedbackInTotalCard";
import StuffMainPage from "../../../DB/stuff-full-list.json";
import EmployeeCard from "../StuffCard";
import {colors} from "../../../theme/default";
import Slider from "../Slider";

const employees = StuffMainPage.sort(() => 0.5 - Math.random()).slice(0, 2);
const feedbacks = FeedbackDB.sort(() => 0.5 - Math.random()).slice(0, 3);


const sections = [
    {id: 'about-service', label: 'Про послугу'},
    {id: 'price', label: 'Ціна'},
    {id: 'feedbacks-for-service', label: 'Відгуки'},
    {id: 'stuff-service', label: 'Фахівці'},
];

const useStyles = makeStyles((theme) => ({
        boxWrapper: {
            display: 'flex',
            gap: '5%',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '0',
            },
        },
        subTitle: {
            fontSize: '28px',
            fontFamily: 'Inter-Bold',
            color: `${colors.outerSpace}`,
            fontWeight: 500,
            lineHeight: '34px',
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                lineHeight: '24px',
                marginBottom: '10px',
            },
        },
        description: {
            fontSize: '20px',
            fontFamily: 'Inter-Regular',
            color: `${colors.outerSpace}`,
            fontWeight: 400,
            lineHeight: '24px',
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '17px',
            },
        },
        titleBlock: {
            fontSize: '40px',
            fontFamily: 'Inter-Bold',
            color: `${colors.outerSpace}`,
            fontWeight: 500,
            lineHeight: '48px',
            marginBottom: '32px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '32px',
                lineHeight: '38px',
                marginBottom: '20px',
                //borderBottom: `1px solid ${colors.outerSpace}`,
            },
        },
        sideBarSection: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            gap: '100px',
            marginBottom: '100px',
            [theme.breakpoints.down('sm')]: {
                gap: '60px',
                marginBottom: '60px',
            },
        },
        stuffBox: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }))
;

const ServiceDetail = () => {
    const classes = useStyles();
    const location = useLocation();
    const currentUrl = location.pathname;

    const selectedService = servicesData.find(service => service.url === currentUrl);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <ServicesBanner
                backgroundImage={StuffBackgroundTest}
                title={selectedService ? selectedService.title : 'Услуга не найдена'}
                description={selectedService ? selectedService.descriptionForBanner : 'Услуга не найдена'}
                price={selectedService ? selectedService.price : 'Услуга не найдена'}
            />
            <SectionWrapper>
                <Box className={classes.boxWrapper}>
                    <Box className={classes.sideBarSection}>
                        <Sidebar sections={sections}/>
                    </Box>
                    {selectedService ? (
                        <>
                            <Box className={classes.content}>
                                <Box id="about-service">
                                    <Typography className={classes.subTitle}>
                                        {selectedService.subTitle}
                                    </Typography>
                                    <Typography
                                        className={classes.description}
                                        variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html: selectedService ? selectedService.fullDescription : 'Услуга не найдена'
                                        }}
                                    />
                                    <Box>
                                        {selectedService && selectedService.note && (
                                            <div>
                                                <Typography className={classes.description} style={{
                                                    fontWeight: '500',
                                                    fontFamily: 'Inter-Bold'
                                                }}> Примітка:</Typography>
                                                <Typography
                                                    className={classes.description}
                                                    variant="body1"
                                                    style={{ marginBottom: '0' }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedService.note
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Box>
                                </Box>
                                <Box id="price">
                                    <Typography className={classes.titleBlock}>
                                        Вартість послуги
                                    </Typography>
                                    <ServicesButton title={selectedService.title} description={selectedService.price} borderTop={`1px solid ${colors.outerSpace}`}/>
                                    <ServicesButton title={selectedService.title} titleSale="(Ціна для власників клубної карти)" description={selectedService.salePrice}/>
                                </Box>
                                <Box id="feedbacks-for-service">
                                    <Typography className={classes.titleBlock}>
                                        Відгуки
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {feedbacks.map((feedback, index) => (
                                            <Grid item xs={12} sm={6} md={6} key={index}>
                                                <ReviewCard
                                                    review={feedback.review}
                                                    date={feedback.date}
                                                    name={feedback.name}
                                                    images={feedback.images}
                                                />
                                            </Grid>
                                        ))}
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FeedbackInTotalCard/>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box id="stuff-service">
                                    <Typography className={classes.titleBlock}>
                                        Фахівці
                                    </Typography>
                                    <Box className={classes.stuffBox}>
                                        {employees.map((employee, index) => (
                                            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                                <EmployeeCard
                                                    to={employee.url}
                                                    firstName={employee.firstName}
                                                    lastName={employee.lastName}
                                                    middleName={employee.middleName}
                                                    specialization={employee.specialization}
                                                    description={employee.description}
                                                    imageSrc={employee.imageSrc}
                                                />
                                            </Grid>
                                        ))}
                                    </Box>
                                    <div className={classes.sliderBox}>
                                        <Slider
                                            projects={employees}
                                            lineColor={`${colors.tifanny}`}
                                            slideComponent={({project}) => (
                                                <EmployeeCard
                                                    to={project.url}
                                                    firstName={project.firstName}
                                                    lastName={project.lastName}
                                                    middleName={project.middleName}
                                                    specialization={project.specialization}
                                                    description={project.description}
                                                    imageSrc={project.imageSrc}
                                                />
                                            )}
                                        />
                                    </div>
                                </Box>

                            </Box>
                        </>
                    ) : (
                        <p>Услуга не найдена</p>
                    )}
                </Box>
            </SectionWrapper>
        </div>
    );
};

export default ServiceDetail;
