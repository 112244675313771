import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Button,
    Select,
    MenuItem,
    TextareaAutosize, Box,
} from '@material-ui/core';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import {colors} from "../../../../theme/default";
import Typography from "@material-ui/core/Typography";
import ArrowRight from "../../../../assets/icon/arrow-right-for-select.svg";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
            marginBottom: "20px",
            width: "100%",
        },
        "& > *:first-child": {
            marginBottom: "16px",
            [theme.breakpoints.down('sm')]: {
                marginBottom: "10px",
            },
        },
        '& > *:nth-child(6)': {
            marginBottom: "16px",
            [theme.breakpoints.down('sm')]: {
                marginBottom: "10px",
            },
        },
    },
    submitButton: {
        color: `${colors.white}`,
        backgroundColor: `${colors.tifanny}`,
        padding: '12px',
        borderRadius: '12px',
        border: `1px solid ${colors.tifanny}`,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            color: `${colors.tifanny}`,
            border: `1px solid ${colors.tifanny}`,
        },
        '&:disabled': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
            opacity: '0.5',
            border: `1px solid ${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    textField: {
        flexGrow: '1',
        flexBasis: '300px',
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: '12px',
        },
        "& .MuiOutlinedInput-root": {
            color: `${colors.outerSpace}`,
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',
            "& fieldset": {
                borderColor: `${colors.outerSpace}`,
                borderRadius: '12px',
                color: `${colors.outerSpace}`,
            },
            "&:hover fieldset": {
                borderColor: `${colors.tifanny}`,
                color: `${colors.white}`,
            },
            "&.Mui-focused fieldset": {
                borderColor: `${colors.tifanny}`,
                color: `${colors.white}`,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        "& .MuiInputLabel-root": {
            color: `${colors.outerSpace}`,
            opacity: '0.8',
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',

            "&.Mui-focused": {
                color: `${colors.tifanny}`,
            },

            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
    },
    selectField: {
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: '12px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none',
            color: `${colors.outerSpace}`,
        },
        "& .MuiOutlinedInput-notchedOutline:hover": {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.white}`,
        },
        "& .MuiSelect-select": {
            borderRadius: '12px',
            width: '100%',
            border: `1px solid ${colors.outerSpace}`,
            color: `${colors.outerSpace}`,
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            lineHeight: '24px',
            opacity: '0.8',
            fontWeight: '400',
            //marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
        '& .MuiSelect-outlined.MuiSelect-outlined:hover': {
            borderColor: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        "& .MuiSelect-select:hover": {
            borderColor: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: 'transparent',
            borderColor: `${colors.tifanny}`,
            borderRadius: '12px',
        },
        '& .MuiSelect-select:active': {
            backgroundColor: 'transparent',
            borderColor: `${colors.tifanny}`,
            borderRadius: '12px',
        },
        '& .MuiSelect-select:focus-visible': {
            borderColor: `${colors.tifanny}`,
            borderRadius: '12px',
            backgroundColor: 'transparent',
        },
        "& .MuiSelect-icon": {
            color: `${colors.outerSpace}`,
        },

    },
    textArea: {
        border: `1px solid ${colors.outerSpace}`,
        borderRadius: '12px',
        color: `${colors.outerSpace}`,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '400',
        padding: '12px',
        width: '100%',
        resize: 'none',
        "&:hover": {
            borderColor: `${colors.tifanny} !important`,
        },
        '&:focus-visible': {
            outline: `2px solid ${colors.tifanny} !important`,
            borderColor: `${colors.tifanny} !important`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    fileInput: {
        display: 'none',
    },
    icon: {
        position: 'absolute',
        right: '18px',
    },
    fileInputLabel: {
        width: '30%',
        marginRight: 'auto',
        backgroundColor: 'transparent',
        color: `${colors.outerSpace}`,
        borderRadius: '12px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        border: `1px solid ${colors.outerSpace}`,
        padding: '18.5px 14px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: 'transparent',
            color: `${colors.tifanny}`,
            border: `1px solid ${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: '0',
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    menuItem: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        color: `${colors.outerSpace}`,
        '&:hover': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
        },
        '&:disabled': {
            color: `${colors.outerSpace}`,
            opacity: '0.5',
        },
        '&:focus': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
        },
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
            opacity: '0.8',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    textFieldWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        paddingBottom: '20px',
        borderBottom: `1px solid ${colors.outerSpace}`
    },
    selectDescription: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        marginBottom: '16px',
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            marginBottom: '10px',
            lineHeight: '16px',
        },
    },
}));

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Введите имя'),
    lastName: Yup.string().required('Введите фамилию'),
    email: Yup.string().email('Введите корректный email').required('Введите email'),
    phoneNumber: Yup.string()
        .matches(/^\d{10}$/, 'Введите корректный номер телефона (10 цифр)')
        .required('Введите номер телефона'),
    selectOption: Yup.string().notOneOf(['Оберіть опцію'], 'Выберите опцию').required('Выберите опцию'),
    textarea: Yup.string().required('Введите текст'),
    file: Yup.mixed().required('Загрузите файл'),
});

const CarerForm = () => {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(null); // Состояние для отслеживания выбранного файла


    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            selectOption: 'Оберіть опцію',
            textarea: '',
            file: null,
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
        },
    });

    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue("file", file);
        setSelectedFile(file); // Устанавливаем выбранный файл в состояние
    };

    return (
        <form className={classes.form}>
            <Typography variant="body1" className={classes.selectDescription}>
                До якої суміжної медичної професії ви належите?
            </Typography>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="selectOption"
                variant="outlined"
                IconComponent={() => <img src={ArrowRight} alt="arrow-right" className={classes.icon}/>}
                defaultValue="Оберіть опцію"
                className={classes.selectField}
                value={formik.values.selectOption}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.selectOption && Boolean(formik.errors.selectOption)}
                helperText={formik.touched.selectOption && formik.errors.selectOption}
            >
                <MenuItem value="Оберіть опцію" disabled className={classes.menuItem}>Оберіть медичну
                    професію</MenuItem>
                <MenuItem value="Опція 1" className={classes.menuItem}>Ерго-терапевт</MenuItem>
                <MenuItem value="Опція 2" className={classes.menuItem}>Психолог</MenuItem>
                <MenuItem value="Опція 3" className={classes.menuItem}>Соціальний працівник</MenuItem>
            </Select>
            <Box className={classes.textFieldWrapper}>
                <TextField
                    label="Ваше ім’я*"
                    name="firstName"
                    variant="outlined"
                    className={classes.textField}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />

                <TextField
                    label="Прізвище*"
                    name="lastName"
                    variant="outlined"
                    className={classes.textField}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />

                <TextField
                    label="Електронна пошта*"
                    name="email"
                    variant="outlined"
                    className={classes.textField}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                    label="Телефон*"
                    name="phoneNumber"
                    variant="outlined"
                    className={classes.textField}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
            </Box>
            <TextareaAutosize
                aria-label="minimum height"
                minRows={7}
                placeholder="Розкажіть нам трохи про себе :"
                name="textarea"
                className={classes.textArea}
                value={formik.values.textarea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.textarea && Boolean(formik.errors.textarea)}
                helperText={formik.touched.textarea && formik.errors.textarea}
            />
            <Typography variant="body1" className={classes.selectDescription}>
                Завантажити резюме ( 3мб ) *
            </Typography>
            <input
                type="file"
                id="fileInput"
                name="file"
                className={classes.fileInput}
                onChange={handleFileChange}
            />
            <label htmlFor="fileInput" className={classes.fileInputLabel}>
                {selectedFile ? selectedFile.name : "Обрати файл"}
            </label>
            <Button
                type="button"
                className={classes.submitButton}
                onClick={formik.handleSubmit}
                disabled={!formik.isValid || formik.isSubmitting}
            >
                Подати заявку
            </Button>
        </form>
    );
};

export default CarerForm;


