import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../theme/default";

type CounterProps = {
    startValue: number;
    endValue: number;
    text?: string;
};

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100px', // Фиксированная ширина элемента
        textAlign: 'right', // Выравнивание текста по правому краю
        fontSize: '40px',
        fontFamily: 'Inter-Bold',
        color: `${colors.outerSpace}`,
        fontWeight: 'bold',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            width: 'unset',
        },
    },
    text: {
        display: 'inline-block',
    },
    plusText: {
        marginLeft: '5px',
    },
}));

const Counter: React.FC<CounterProps> = ({ startValue, endValue, text }) => {
    const [count, setCount] = useState(startValue);
    const classes = useStyles();

    useEffect(() => {
        const difference = Math.abs(endValue - startValue);
        const interval = Math.max(1, Math.floor(1200 / difference)); // Рассчитываем интервал обновления

        const timer = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount === endValue) {
                    clearInterval(timer);
                    return prevCount;
                } else {
                    const increment = prevCount < endValue ? 1 : -1;
                    return prevCount + increment;
                }
            });
        }, interval); // Используем рассчитанный интервал обновления

        return () => {
            clearInterval(timer); // Очистка таймера при размонтировании компонента
        };
    }, [startValue, endValue]);

    return (
        <div className={classes.container}>
            <span className={classes.text}>
                {count}
            </span>
            {text && <span className={classes.plusText}>{text}</span>}
        </div>
    );
};

export default Counter;
