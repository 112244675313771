import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Tabs, Tab, Typography, Link, Box, Button} from '@material-ui/core';
import {colors} from '../../../theme/default';

const useStyles = makeStyles((theme) => ({
    tabButton: {
        backgroundColor: 'transparent',
        alignItems: 'flex-start',
        padding: '0',
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'flex-start',
    },
    root: {
        display: 'flex',
        gap: '5%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '5%',
        },
    },
    tabs: {
        top: '120px',
        zIndex: '1',
        position: 'sticky',
        '& .MuiButton-label': {
            display: 'flex',
        },
        '& .MuiTabs-flexContainerVertical': {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            position: 'sticky',
            top: '125px',
            height: '100vh',
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .MuiTab-root': {
            padding: '0',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
                [theme.breakpoints.down('sm')]: {
                    backgroundColor: `${colors.congressBlue}`,
                },
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        '& .MuiTab-wrapper': {
            textAlign: 'left',
            alignItems: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                alignItems: 'center'
            }
        },
        [theme.breakpoints.down('sm')]: {
            top: 'unset',
            position: 'relative',
            '& .MuiTabs-flexContainerVertical': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridRowGap: '10px',
                gridColumnGap: '20px',
                top: '0',
                position: 'relative',
                height: 'auto',
                marginBottom: '40px',
            },
        },
    },
    tab: {
        fontSize: '28px',
        fontFamily: 'Inter-Regular',
        fontWeight: 500,
        lineHeight: '42px',
        cursor: 'pointer',
        textTransform: 'none',
        opacity: '1',
        color: `${colors.outerSpace}`,
        '&:hover': {
            color: `${colors.tifanny}`,
        },
        '&.Mui-selected': {
            color: `${colors.tifanny}`,
            [theme.breakpoints.down('sm')]: {
                backgroundColor: `${colors.congressBlue}`,
                fontFamily: 'Inter-Bold',
                fontWeight: '700',
                color: `${colors.white}`
            },
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '24px',
            color: '#1B2828CC',
            border: `1px solid ${colors.congressBlue}`,
            borderRadius: '6px',
            textAlign: 'center'
        },
    },
    navigationBlock: {
        width: '100%'
    },
    productButton: {
        backgroundColor: 'transparent',
        alignItems: 'flex-start',
        padding: '0',
        border: 'none',
        textAlign: 'left',
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    productInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '55px',
        width: '100%',
        padding: '20px 0',
        transition: '250ms ease-in-out',
        borderBottom: `1px solid ${colors.outerSpace}`,
        '&:hover': {
            backgroundColor: 'transparent',
            borderBottom: `1px solid ${colors.tifanny}`,
            "& $productTitle, & $productDescription, & $productPrice": {
                color: `${colors.tifanny}`,
            },
        },
        [theme.breakpoints.down('sm')]: {
            gap: 'unset'
        },
    },
    productTitle: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        letterSpacing: '0em',
        fontWeight: 400,
        lineHeight: '24px',
        width: '40%',
        textAlign: 'left',
        textTransform: 'none',
        color: `${colors.outerSpace}`,
        transition: 'color 250ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
            width: '50%',
        },

    },
    productDescription: {
        fontSize: '16px',
        fontFamily: 'Inter-Regular',
        fontWeight: 400,
        lineHeight: '19px',
        width: '50%',
        textAlign: 'left',
        textTransform: 'none',
        color: `${colors.outerSpace}`,
        transition: 'color 250ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

    },
    productPrice: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        fontWeight: 500,
        lineHeight: '19px',
        width: '10%',
        textAlign: 'right',
        textWrap: 'nowrap',
        textTransform: 'none',
        color: `${colors.outerSpace}`,
        transition: 'color 250ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '30%',
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    subtitle: {
        fontSize: '54px',
        fontFamily: 'Inter-Regular',
        fontWeight: 400,
        lineHeight: '65px',
        color: `${colors.outerSpace}`,
        textTransform: 'none',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            lineHeight: '38px',
            marginBottom: '0',
            paddingBottom: '20px',
            borderBottom: `1px solid ${colors.outerSpace}`,
        },
    }
}));

function TabMenu({ tabsData }) {
    const classes = useStyles();
    const [value, setValue] = useState(0); // Устанавливаем начальное значение в 0
    const [showSubtitles, setShowSubtitles] = useState(true); // Показываем контент по умолчанию

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setShowSubtitles(true);

        scrollToTop();
    };

    const rootRef = useRef(null);

    const headerHeight = 120; // Пример высоты хедера в пикселях

    const scrollToTop = () => {
        if (rootRef.current) {
            const topOffset = rootRef.current.offsetTop - headerHeight;
            window.scrollTo({ top: topOffset, behavior: 'smooth' });
        }
    };

    return (
        <div className={classes.root} ref={rootRef}>
            <Box>
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                >
                    {tabsData.map((tab, index) => (
                        <Tab
                            key={index}
                            className={classes.tab}
                            label={tab.title}
                        />
                    ))}
                </Tabs>
            </Box>
            {showSubtitles && (
                <div className={classes.navigationBlock}>
                    <Typography variant="h2" className={classes.subtitle}>
                        {tabsData[value].title}
                    </Typography>
                    <Box>
                        {tabsData[value].items.map((item, index) => (
                            <Box key={index} className={classes.li}>
                                <Link href={item.url} style={{ textDecoration: 'none' }}>
                                    <Button className={classes.productButton}>
                                        <div className={classes.productInfo}>
                                            <Typography variant="subtitle1" className={classes.productTitle}>
                                                {item.text}
                                            </Typography>
                                            <Typography className={classes.productDescription}>
                                                {item.description}
                                            </Typography>
                                            <Typography className={classes.productPrice}>
                                                {item.price}
                                            </Typography>
                                        </div>
                                    </Button>
                                </Link>
                            </Box>
                        ))}
                    </Box>
                </div>
            )}
        </div>
    );
}

export default TabMenu;

