import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../theme/default";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
            margin: theme.spacing(1),
            width: "100%", // Ширина полей формы
        },
    },
    submitButton: (props) => ({
        color: props.theme === "default" ? colors.outerSpace : colors.white,
        backgroundColor: props.theme === "default" ? colors.white : colors.outerSpace,
        padding: "12px",
        borderRadius: "12px",
        border: `1px solid ${
            props.theme === "default" ? colors.white : colors.outerSpace
        }`,
        fontFamily: "Inter-Regular",
        fontSize: "20px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "transparent",
            color: props.theme === "default" ? colors.white : colors.outerSpace,
            border: `1px solid ${
                props.theme === "default" ? colors.outerSpace : colors.white
            }`,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    }),
    textField: (props) => ({
        "& .MuiOutlinedInput-root": {
            color: props.theme === "default" ? colors.white : colors.outerSpace,
            fontFamily: "Inter-Regular",
            fontSize: "20px",
            fontWeight: "400",
            "& fieldset": {
                borderColor:
                    props.theme === "default" ? colors.white : colors.outerSpace,
                borderRadius: "12px",
                color: props.theme === "default" ? colors.white : colors.outerSpace,
            },
            "&:hover fieldset": {
                borderColor:
                    props.theme === "default" ? colors.tifanny : colors.tifanny,
                color: props.theme === "default" ? colors.white : colors.outerSpace,
            },
            "&.Mui-focused fieldset": {
                borderColor:
                    props.theme === "default" ? colors.tifanny : colors.tifanny,
                color: props.theme === "default" ? colors.white : colors.outerSpace,
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
            },
        },
        "& .MuiInputLabel-root": {
            color: props.theme === "default" ? colors.white : colors.outerSpace,
            opacity: "0.8",
            fontFamily: "Inter-Regular",
            fontSize: "20px",
            fontWeight: "400",
            "&.Mui-focused": {
                color: props.theme === "default" ? colors.tifanny : colors.outerSpace,
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
            },
        },
    }),
}));

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Введите ваше имя"),
    email: Yup.string()
        .email("Введите корректный email")
        .required("Введите ваш email"),
    phone: Yup.string()
        .matches(/^[0-9]{10}$/, "Введите корректный номер телефона")
        .required("Введите ваш номер телефона"),
});

function ContactForm({ theme }) {
    const classes = useStyles({ theme });

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("Форма отправлена с данными:", values);
        },
    });

    return (
        <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
                label="Ваше ім’я"
                name="name"
                variant="outlined"
                className={classes.textField}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
                label="Email"
                name="email"
                variant="outlined"
                className={classes.textField}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
                label="Номер телефону"
                name="phone"
                variant="outlined"
                className={classes.textField}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
            />

            <Button type="submit" className={classes.submitButton}>
                Надіслати
            </Button>
        </form>
    );
}

export default ContactForm;

