import React from 'react';
import Banner from '../../UI/PhotoBanner';
import AboutUsBanner from '../../../assets/img/banners/AboutUsBanner.jpg';
import Sidebar from "../../UI/SideBarStatic";
import SectionWrapper from "../../UI/SectionWrapper";
import {Box} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import CompanyHistory from "./History";
import Licenses from "./Licenses";
import Methods from "./Methods";
import WorkingStuff from "./WorkingStuff";
import StatePrograms from "./StatePrograms";
import {useEffect} from 'react';

const sections = [
    {id: 'about', label: 'О Space clinic'},
    {id: 'methods', label: 'Методи лікування'},
    {id: 'working-stuff', label: 'Робочий персонал'},
    {id: 'state-programs', label: 'Державні програми'},
];

const useStyles = makeStyles((theme) => ({
    boxWrapper: {
        display: 'flex',
        gap: '5%',
    },
    sideBarSection: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        gap: '100px',
        marginBottom: '100px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '60px',
            gap: '60px'
        },
    },
}));

const About = () => {
    const classes = useStyles();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Banner
                backgroundImage={AboutUsBanner}
                title="О нас"
                description="Space clinic - це медичний центр, який надає послуги з лікування та профілактики. Ми пропонуємо широкий спектр послуг, які допоможуть вам відновити здоров'я та красу."
            />
            <SectionWrapper>
                <Box className={classes.boxWrapper}>
                    <Box className={classes.sideBarSection}>
                        <Sidebar sections={sections}/>
                    </Box>
                    <Box className={classes.content}>
                        <Box id="about">
                            <CompanyHistory/>
                        </Box>
                        <Box id="licenses">
                            <Licenses/>
                        </Box>
                        <Box id="methods">
                            <Methods/>
                        </Box>
                        <Box id="working-stuff">
                            <WorkingStuff/>
                        </Box>
                        <Box id="state-programs">
                            <StatePrograms/>
                        </Box>
                    </Box>
                </Box>
            </SectionWrapper>
        </div>
    );
};

export default About;
