import React from 'react';
import Banner from '../../UI/PhotoBanner';
import {useEffect} from 'react';
import StuffBackgroundTest from '../../../assets/img/StuffBackgroundTest.jpg';
import SectionWrapper from "../../UI/SectionWrapper";
import {Grid} from "@material-ui/core"; // Импортируем Grid из Material-UI
import StuffFullList from "../../../DB/stuff-full-list.json";
import EmployeeCard from "../../UI/StuffCard";
import RichLink from "../../UI/RichLinks";


const employees = StuffFullList.slice().sort((a, b) => a.index - b.index);

const Stuff = () => {
    const pageTitle = 'Фахівці';
    const pageDescription = 'Test description';
    const pageImage = {StuffBackgroundTest};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <RichLink title={pageTitle} description={pageDescription} image={pageImage} />
            <Banner
                backgroundImage={StuffBackgroundTest}
                title="Фахівці"
                description="Наші фахівці готові допомогти вам у вирішенні будь-яких питань"
            />
            <SectionWrapper marginBottom="100px">
                <Grid container spacing={2}>
                    {employees.map((employee, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                                <EmployeeCard
                                    to={employee.url}
                                    firstName={employee.firstName}
                                    index={index}
                                    lastName={employee.lastName}
                                    middleName={employee.middleName}
                                    specialization={employee.specialization}
                                    description={employee.description}
                                    imageSrc={employee.imageSrc}
                                />
                        </Grid>
                    ))}
                </Grid>
            </SectionWrapper>
        </div>
    );
};

export default Stuff;
