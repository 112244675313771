import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {colors} from '../../../theme/default';
import SectionWrapper from "../SectionWrapper";
import Breadcrumbs from "../Breadcrumbs";
import ContactModal from "../../pages/ModalWindow/AppointmentModal";


const useStyles = makeStyles((theme) => ({
    banner: {
        width: '100%',
        height: '105vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    content: {
        position: 'relative',
        gap: '50%',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px',
            gap: '40%',
        },
    },
    breadcrumb: {
        textAlign: 'left',
    },
    title: {
        fontSize: '80px',
        fontFamily: 'Inter-Regular',
        color: `${colors.white}`,
        fontWeight: 500,
        fontHeight: '97px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            lineHeight: '34px',
        },
    },
    description: {
        color: `${colors.white}`,
        fontFamily: 'Inter-Regular',
        fontWeight: 400,
        width: '50%',
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'left',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    // button: {
    //     color: `${colors.white}`,
    //     backgroundColor: `${colors.tifanny}`,
    //     border: `1px solid ${colors.tifanny}`,
    //     borderRadius: '10px',
    //     padding: '20px',
    //     fontFamily: 'Inter-Regular',
    //     fontSize: '20px',
    //     lineHeight: '24px',
    //     fontWeight: '400',
    //     textTransform: 'none',
    //
    //     '&:hover': {
    //         border: `1px solid ${colors.tifanny}`,
    //         color: `${colors.tifanny}`,
    //         backgroundColor: 'transparent',
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: '14px',
    //         lineHeight: '17px',
    //         padding: '8px',
    //     },
    // },
    boxWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '5%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            gap: 'unset',
            justifyContent: 'space-between',
        },
    },
    price: {
        fontFamily: 'Inter-Bold',
        fontSize: '32px',
        fontWeight: '700',
        lineHeight: '39px',
        letterSpacing: '0em',
        color: `${colors.white}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '24px',
        },
    }
}));

const ServicesBanner = ({backgroundImage, title, description, price}) => {
    const classes = useStyles();
    return (
        <div
            className={classes.banner}
            style={{backgroundImage: `url(${backgroundImage})`}}
        >
            <div className={classes.overlay}></div>
            <SectionWrapper backgroundColor="transparent" height="70%">
                <Box className={classes.content}>
                    <Breadcrumbs/>
                    <Box>
                        <Typography variant="h1" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography variant="h3" className={classes.description}>
                            {description}
                        </Typography>
                        <Box className={classes.boxWrapper}>
                            <ContactModal buttonText="Записатися на прийом" padding="20px"/>
                            <Typography className={classes.price}>
                                {price}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </SectionWrapper>
        </div>
    );
};

export default ServicesBanner;