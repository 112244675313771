import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../theme/default";
import {Box} from "@material-ui/core";
import ReviewExample from "../../../assets/img/ReviewExample.png";
import StyledButton from "../StyledButton";

const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
        borderRadius: "20px",
        boxShadow: "none",
        border: `1px solid ${colors.outerSpace}`,
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        [theme.breakpoints.down('sm')]: {
            borderRadius: "18px",
        },
    },
    cardContent: {
        flexGrow: 1,
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "space-between", // Равномерное распределение между верхним и нижним контентом
    },
    userContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "48px",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "20px",
        },
    },
    text: {
        fontSize: "20px",
        fontFamily: "Inter-Regular",
        lineHeight: "24px",
        fontWeight: "500",
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
            lineHeight: "20px",
            marginBottom: "20px",
        },
    },
    reviewCount: {
        fontSize: "32px",
        fontWeight: "600",
        fontFamily: "Inter-Bold",
        lineHeight: "38px",
        color: `${colors.outerSpace}`,
        marginBottom: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            lineHeight: "22px",
        },
    },
    buttonContainer: {
        width: '75%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

function StaticCard() {
    const classes = useStyles();

    return (
        <Box className={classes.card}>
            <Box className={classes.cardContent}>
                <Box className={classes.userContainer}>
                    <img src={ReviewExample} alt="ReviewExample"/>
                </Box>
                <Typography variant="body2" className={classes.reviewCount}>
                    + 100 відгуків
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    Відгуків наших клієнтів
                </Typography>
            </Box>
            <Box className={classes.buttonContainer}>
                <StyledButton variant="default" width="100%" text="Всі відгуки" to="/about/feedbacks"/>
            </Box>
        </Box>
    );
}

export default StaticCard;
