import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../theme/default';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        borderRadius: '20px',
        overflow: 'hidden',
        width: '100%',
        boxShadow: '0px 0px 24px 0px #00244614',
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.7s ease-in-out',
        cursor: 'pointer',
        height: (props) => (props.height ? props.height : 'unset'),
        '&:hover img': {
            transform: 'scale(1.2)',
        },
        '&:hover $title': {
            color: `${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            boxShadow: (props) => (props.boxShadow ? 'none' : '0px 0px 24px 0px #00244614'),
            borderRadius: '18px',
            height: '100% !important',
        },
    },
    imageContainer: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        maxHeight: '100%', // Максимальная высота контейнера для изображения
    },
    image: {
        width: '100%',
        height: '350px',
        objectFit: 'cover',
        objectPosition: 'center',
        transition: 'transform 0.7s',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
            objectFit: 'cover',
            //height: '100%',
            objectPosition: 'center',
        },
    },
    overlay: {
        backgroundColor: 'white',
        color: `${colors.outerSpace}`,
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1',
    },
    title: {
        fontSize: '20px',
        fontWeight: '700',
        fontFamily: 'Inter-Bold',
        lineHeight: '24px',
        marginBottom: '10px',
        color: `${colors.outerSpace}`,
        transition: 'all 0.7s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    description: {
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Inter-Regular',
        lineHeight: '19px',
        color: `${colors.outerSpace}`,
        marginTop: 'auto',
        lineClamp: 3,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',

        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
}));

function ImageOverlayCard({ imageSrc, title, description, height, boxShadow }) {
    const classes = useStyles({ height, boxShadow });

    return (
        <Box className={classes.card}>
            <Box className={classes.imageContainer}>
                <img src={imageSrc} alt={title} className={classes.image} />
            </Box>
            <Box className={classes.overlay}>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <Typography variant="body2" className={classes.description}>
                    {description}
                </Typography>
            </Box>
        </Box>
    );
}

export default ImageOverlayCard;


