import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from "@material-ui/core";
import Banner from "../../UI/PhotoBanner";
import SectionWrapper from "../../UI/SectionWrapper";
import Button from "@material-ui/core/Button";
import { colors } from "../../../theme/default";
import TestBanner from "../../../assets/img/banners/AboutUsBanner.jpg";
import photos from "../../../assets/img/galleryImageList"; // Импорт списка путей к изображениям

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    loadMoreButton: {
        color: `${colors.white}`,
        backgroundColor: `${colors.tifanny}`,
        border: `1px solid ${colors.tifanny}`,
        borderRadius: '10px',
        padding: '10px 20px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        textTransform: 'none',
        boxShadow: 'none',

        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.tifanny}`,
            boxShadow: 'none',
            backgroundColor: 'transparent',
        },
        '&:disabled': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.white}`,
            boxShadow: 'none',
            backgroundColor: `${colors.tifanny}`,
            opacity: '0.5',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
            width: '100%'
        },
    },
    buttonWrapper: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    photoWrapper1x1: {
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
        overflow: 'hidden',
        marginBottom: '20px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '16px',
            objectPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            [theme.breakpoints.down('sm')]: {
                borderRadius: '10px',
            },
        }
    },
    photoWrapper2x1: {
        width: '100%',
        paddingBottom: '70%',
        position: 'relative',
        overflow: 'hidden',
        marginBottom: '20px',
        '& img': {
            width: '100%',
            height: '100%',
            borderRadius: '16px',
            objectFit: 'cover',
            objectPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            [theme.breakpoints.down('sm')]: {
                borderRadius: '10px',
            },
        },
    },
    boxWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

const GalleryPage = () => {
    const classes = useStyles();
    const [visiblePhotos, setVisiblePhotos] = useState(8);
    const loadMoreStep = 8;

    const handleLoadMoreClick = () => {
        setVisiblePhotos(visiblePhotos + loadMoreStep);
    };

    const isLoadMoreDisabled = visiblePhotos >= photos.length;

    // Создаем функцию, чтобы получить изображение по индексу с учетом длины массива
    const getImageByIndex = (index) => {
        if (index < photos.length) {
            return photos[index];
        }
        return null; // Если индекс больше или равен длине массива, возвращаем null
    };

    // Равномерное распределение изображений между столбцами
    const leftColumn = [];
    const rightColumn = [];

    for (let i = 0; i < visiblePhotos; i++) {
        if (i % 2 === 0) {
            leftColumn.push(getImageByIndex(i));
        } else {
            rightColumn.push(getImageByIndex(i));
        }
    }

    return (
        <Box>
            <Banner
                backgroundImage={TestBanner}
                title='Галерея'
            />
            <SectionWrapper marginBottom="100px" paddingTop="60px">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {leftColumn.map((photo, index) => (
                            <Box
                                key={index}
                                className={photo ? (index % 2 === 0 ? classes.photoWrapper1x1 : classes.photoWrapper2x1) : ''}
                            >
                                {photo && <img src={photo.src} alt={photo.alt} />}
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {rightColumn.map((photo, index) => (
                            <Box
                                key={index}
                                className={photo ? (index % 2 === 0 ? classes.photoWrapper2x1 : classes.photoWrapper1x1) : ''}
                            >
                                {photo && <img src={photo.src} alt={photo.alt} />}
                            </Box>
                        ))}
                    </Grid>
                </Grid>
                <Box className={classes.buttonContainer}>
                    <Button
                        className={classes.loadMoreButton}
                        variant="contained"
                        onClick={handleLoadMoreClick}
                        disabled={isLoadMoreDisabled}
                    >
                        Завантажити ще
                    </Button>
                </Box>
            </SectionWrapper>
        </Box>
    );
};

export default GalleryPage;



