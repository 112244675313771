import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'; // Иконка с тремя точками
import {colors} from '../../../../theme/default';
import {Box, Icon} from "@material-ui/core";
import {ReactComponent as InstagramIcon} from '../../../../assets/icon/instagram-icon.svg';
import {ReactComponent as TelegramIcon} from '../../../../assets/icon/telegram-icon.svg';
import ContactModal from "../AppointmentModal";

const useStyles = makeStyles((theme) => ({
    floatingButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: `${colors.tifanny}`,
            zIndex: 1400,
            borderRadius: '50%', // Делаем кнопку круглой
            width: '60px !important', // Установите желаемый размер
            height: '60px !important',
            padding: 0, // Убираем внутренние отступы
            border: 'none !important',
            outline: 'none !important',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        margin: '0 20px 85px 0',
    },
    paper: {
        backgroundColor: `${colors.white}`,
        borderRadius: '10px',
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 'none',
        padding: '20px 17px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
    },
    iconButton: {
        color: `${colors.white}`,
        width: '40px !important', // Устанавливаем желаемый размер
        height: '40px !important',
    },
    socialIcon: {
        [theme.breakpoints.down('sm')]: {
            width: '16px',
            height: '16px',
        },
    },
    socialButton: {
        padding: '10px 20px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        backgroundColor: 'transparent',
        border: `1px solid ${colors.outerSpace}`,
        borderRadius: '10px',
        color: `${colors.outerSpace}`,
        textTransform: 'none',
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '25px',
            [theme.breakpoints.down('sm')]: {
                gap: '10px',
            },
        },
        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.tifanny}`,
            backgroundColor: 'transparent',
            transition: 'all 0.3s ease-in-out',
            '& $path': {
                transition: 'all 0.3s ease-in-out',
                fill: `${colors.tifanny}`,
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
            fontSize: '14px',
        },
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    }
}));

const FloatingButton = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <button className={classes.floatingButton} onClick={handleOpen}>
                    <MoreHorizIcon className={classes.iconButton}/>
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <button className={classes.floatingButton} onClick={handleClose}>
                            <CloseIcon className={classes.iconButton}/>
                    </button>
                    <Box className={classes.buttonWrapper}>
                        <Button className={classes.socialButton}
                                onClick={() => window.open('https://www.instagram.com/space_clinic/', '_blank')}>
                            <Icon
                                component={InstagramIcon}
                                className={classes.socialIcon}
                                src={InstagramIcon}
                            />
                            Instagram
                        </Button>
                        <Button className={classes.socialButton}
                                onClick={() => window.open('https://web.telegram.org/', '_blank')}>
                            <Icon
                                component={TelegramIcon}
                                className={classes.socialIcon}
                                src={TelegramIcon}
                            />
                            Telegram
                        </Button>
                        <ContactModal buttonText="Консультація" titleText="Записатися на консультацію"/>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default FloatingButton;

