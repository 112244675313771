import React, {useEffect} from 'react';
import StuffBackgroundTest from '../../../../assets/img/StuffBackgroundTest.jpg';
import SectionWrapper from '../../../UI/SectionWrapper';
import {useLocation} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import Banner from "../../../UI/PhotoBanner";
import stuffData from "../../../../DB/stuff-full-list.json";
import Typography from "@material-ui/core/Typography";
import {Box, Button} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EmployeeCard from "../../../UI/StuffCard";
import Slider from "../../../UI/Slider";
import {colors} from "../../../../theme/default";
import StyledButton from "../../../UI/StyledButton";

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontFamily: 'Inter-Bold',
        fontSize: 40,
        fontWeight: 700,
        lineHeight: '48px',
        letterSpacing: '0em',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    description: {
        fontFamily: 'Inter-Regular',
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    mainBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '100px',
        [theme.breakpoints.down('sm')]: {
            gap: '60px',
        },
    },
    buttonWrapper: {
        width: '25%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    boxWrapper: {
        display: 'flex',
        gap: '5%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '20px',
        },
    },
    textBox: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    photo: {
        width: '45%',
        borderRadius: '24px',
        // height: '50vh',
        aspectRatio: '3/4',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    boxWrapperReverse: {
        display: 'flex',
        gap: '5%',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            gap: '20px',
        },
    },
    stuffBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    productButton: {
        backgroundColor: 'transparent',
        alignItems: 'flex-start',
        padding: '0',
        border: 'none',
        textAlign: 'left',
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    productInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '55px',
        width: '100%',
        padding: '20px 0',
        transition: '250ms ease-in-out',
        borderBottom: `1px solid ${colors.outerSpace}`,
        '&:hover': {
            backgroundColor: 'transparent',
            borderBottom: `1px solid ${colors.tifanny}`,
            "& $productTitle, & $productDescription, & $productPrice": {
                color: `${colors.tifanny}`,
            },
        },
        [theme.breakpoints.down('sm')]: {
            gap: 'unset'
        },
    },
    productTitle: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        letterSpacing: '0em',
        fontWeight: 400,
        lineHeight: '24px',
        width: '40%',
        textAlign: 'left',
        textTransform: 'none',
        color: `${colors.outerSpace}`,
        transition: 'color 250ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
            width: '50%',
        },

    },
    productDescription: {
        fontSize: '16px',
        fontFamily: 'Inter-Regular',
        fontWeight: 400,
        lineHeight: '19px',
        width: '50%',
        textAlign: 'left',
        textTransform: 'none',
        color: `${colors.outerSpace}`,
        transition: 'color 250ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

    },
    productPrice: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        fontWeight: 500,
        lineHeight: '19px',
        width: '10%',
        textAlign: 'right',
        textWrap: 'nowrap',
        textTransform: 'none',
        color: `${colors.outerSpace}`,
        transition: 'color 250ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '30%',
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
}));

const StuffPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const currentUrl = location.pathname;

    const selectedStuff = stuffData.find(stuff => stuff.url === currentUrl);
    const otherStuff = stuffData.filter(stuff => stuff.url !== currentUrl);
    const randomStuff = otherStuff.sort(() => Math.random() - Math.random()).slice(0, 2);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Banner
                backgroundImage={selectedStuff ? selectedStuff.imageSrcBanner : StuffBackgroundTest}
                title={`${selectedStuff.lastName} ${selectedStuff.firstName} ${selectedStuff.middleName}`}
                description={selectedStuff ? selectedStuff.specialization : 'Услуга не найдена'}
            />
            <SectionWrapper paddingBottom="32px">
                <Box className={classes.mainBox}>
                    <Box className={classes.boxWrapper}>
                        <img src={selectedStuff ? selectedStuff.imageSrc : StuffBackgroundTest} alt=""
                             className={classes.photo}/>
                        <Box className={classes.textBox}>
                            <Typography variant='h3' className={classes.description}>
                                {selectedStuff ? selectedStuff.longDescription : 'Услуга не найдена'}
                            </Typography>
                        </Box>
                    </Box>
                    <>
                        {selectedStuff.consultationTitle ? (
                            <Box className={classes.boxWrapperReverse}>
                                <img src={selectedStuff ? selectedStuff.imageSrcSubDescription : StuffBackgroundTest}
                                     alt=""
                                     className={classes.photo}/>
                                <Box className={classes.textBox}>
                                    <Typography variant='h3' className={classes.title}>
                                        {selectedStuff ? selectedStuff.consultationTitle : 'Услуга не найдена'}
                                    </Typography>
                                    <Typography
                                        className={classes.description}
                                        variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html: selectedStuff ? selectedStuff.consultation : 'Услуга не найдена'
                                        }}
                                    />
                                </Box>
                            </Box>
                        ) : null }
                    </>
                    {selectedStuff.servicesTitle ? (
                        <Box>
                            <Typography variant='h3' className={classes.title}>
                                {selectedStuff ? selectedStuff.servicesTitle : 'Услуга не найдена'}
                            </Typography>
                            {selectedStuff && selectedStuff.services && selectedStuff.services.map((service, index) => (
                                <Button className={classes.productButton}>
                                    <div className={classes.productInfo}>
                                        <Typography variant="subtitle1" className={classes.productTitle}>
                                            {service.title}
                                        </Typography>
                                        <Typography className={classes.productDescription}>
                                            {service.description}
                                        </Typography>
                                        <Typography className={classes.productPrice}>
                                            {service.price}
                                        </Typography>
                                    </div>
                                </Button>
                            ))}
                        </Box>
                    ) : null}
                    <Box style={{marginBottom: '32px'}}>
                        <Typography variant='h3' className={classes.title}>
                            Інші фахівці кліники
                        </Typography>
                        <Box className={classes.stuffBox}>
                            {randomStuff.slice(0, 2).map((employee, index) => (
                                <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                    <EmployeeCard
                                        to={employee.url}
                                        firstName={employee.firstName}
                                        lastName={employee.lastName}
                                        middleName={employee.middleName}
                                        specialization={employee.specialization}
                                        description={employee.description}
                                        imageSrc={employee.imageSrc}
                                    />
                                </Grid>
                            ))}
                        </Box>
                        <div className={classes.sliderBox}>
                            <Slider
                                projects={randomStuff}
                                lineColor={`${colors.tifanny}`}
                                slideComponent={({project}) => (
                                    <EmployeeCard
                                        to={project.url}
                                        firstName={project.firstName}
                                        lastName={project.lastName}
                                        middleName={project.middleName}
                                        specialization={project.specialization}
                                        description={project.description}
                                        imageSrc={project.imageSrc}
                                    />
                                )}
                            />
                        </div>
                    </Box>
                </Box>
                <Box className={classes.buttonWrapper}>
                    <StyledButton text="Усі фахівці" variant="defaultButton" width='100%' to="/stuff"/>
                </Box>
            </SectionWrapper>
        </div>
    );
};

export default StuffPage;