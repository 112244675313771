import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import {colors} from '../../../../theme/default';
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import {Box, MenuItem, Select, TextareaAutosize} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useFormik} from "formik";
import * as Yup from "yup";
import ArrowDown from "../../../../assets/icon/arrow-select-down.svg";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '70%',
        borderRadius: '20px',
        backgroundColor: `${colors.white}`,
        border: 'none !important',
        outline: 'none !important',
        display: 'flex',
        flexDirection: 'column',
        gap: '60px',
        padding: '20px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            gap: '40px',
            padding: '20px',
        },
    },
    closeButton: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        cursor: 'pointer',
        color: `${colors.outerSpace}`,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transition: 'all 0.3s ease-in-out',
            color: `${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            top: '10px',
            right: '10px',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    input: {
        width: '100%',
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    socialButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',

    },
    mainButton: {
        color: `${colors.white}`,
        backgroundColor: `${colors.tifanny}`,
        border: `1px solid ${colors.tifanny}`,
        borderRadius: '10px',
        padding: '10px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        textTransform: 'none',

        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        '&:disabled': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
            opacity: '0.5',
            border: `1px solid ${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px !important',
            fontSize: '14px',
        },
    },
    title: {
        fontFamily: 'Inter-Regular',
        fontSize: '48px',
        lineHeight: '58px',
        fontWeight: '400',
        color: `${colors.outerSpace}`,
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '24px',
            width: '80%'
        },
    },
    description: {
        fontFamily: 'Inter-Regular',
        opacity: '0.6',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '400',
        color: `${colors.outerSpace}`,
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '14px',
        },
    },

    textField: {
        '& .MuiFormControl-root': {
            width: '50%',
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: '12px',
        },
        "& .MuiOutlinedInput-root": {
            color: `${colors.outerSpace}`,
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',
            "& fieldset": {
                borderColor: `${colors.outerSpace}`,
                borderRadius: '12px',
                color: `${colors.outerSpace}`,
            },
            "&:hover fieldset": {
                borderColor: `${colors.tifanny}`,
                color: `${colors.white}`,
            },
            "&.Mui-focused fieldset": {
                borderColor: `${colors.tifanny}`,
                color: `${colors.white}`,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        "& .MuiInputLabel-root": {
            color: `${colors.outerSpace}`,
            opacity: '0.8',
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',

            "&.Mui-focused": {
                color: `${colors.tifanny}`,
            },

            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
    },
    inputWrapper: {
        width: '100%',
        display: 'flex',
        gap: '20px',
        '& .MuiFormControl-root': {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '16px',
        },
    },
    textArea: {
        border: `1px solid ${colors.outerSpace}`,
        borderRadius: '12px',
        color: `${colors.outerSpace}`,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '400',
        padding: '12px',
        width: '100%',
        resize: 'none',
        "&:hover": {
            borderColor: `${colors.tifanny} !important`,
        },
        '&:focus-visible': {
            outline: `2px solid ${colors.tifanny} !important`,
            borderColor: `${colors.tifanny} !important`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    subTitle: {
        fontFamily: 'Inter-Bold',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700',
        color: `${colors.outerSpace}`,
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    fileInput: {
        display: 'none',
    },
    icon: {
        position: 'absolute',
        right: '18px',
    },
    fileInputLabel: {
        width: '50%',
        marginRight: 'auto',
        backgroundColor: 'transparent',
        color: `${colors.outerSpace}`,
        borderRadius: '12px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        border: `1px solid ${colors.outerSpace}`,
        padding: '18.5px 14px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: 'transparent',
            color: `${colors.tifanny}`,
            border: `1px solid ${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: '0',
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    menuItem: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        color: `${colors.outerSpace}`,
        '&:hover': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
        },
        '&:disabled': {
            color: `${colors.outerSpace}`,
            opacity: '0.5',
        },
        '&:focus': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
        },
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: `${colors.tifanny}`,
            color: `${colors.white}`,
            opacity: '0.8',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    selectField: {
        width: '50%',
        marginBottom: '20px',
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: '12px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none',
            color: `${colors.outerSpace}`,
        },
        "& .MuiOutlinedInput-notchedOutline:hover": {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.white}`,
        },
        "& .MuiSelect-select": {
            borderRadius: '12px',
            width: '100%',
            border: `1px solid ${colors.outerSpace}`,
            color: `${colors.outerSpace}`,
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            lineHeight: '24px',
            opacity: '0.8',
            fontWeight: '400',
            //marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
        '& .MuiSelect-outlined.MuiSelect-outlined:hover': {
            borderColor: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        "& .MuiSelect-select:hover": {
            borderColor: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: 'transparent',
            borderColor: `${colors.tifanny}`,
            borderRadius: '12px',
        },
        '& .MuiSelect-select:active': {
            backgroundColor: 'transparent',
            borderColor: `${colors.tifanny}`,
            borderRadius: '12px',
        },
        '& .MuiSelect-select:focus-visible': {
            borderColor: `${colors.tifanny}`,
            borderRadius: '12px',
            backgroundColor: 'transparent',
        },
        "& .MuiSelect-icon": {
            color: `${colors.outerSpace}`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    selectFileWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Введите имя'),
    phoneNumber: Yup.string()
        .matches(/^\d{10}$/, 'Введите корректный номер телефона (10 цифр)')
        .required('Введите номер телефона'),
    textarea: Yup.string().required('Введите текст'),
    selectOption: Yup.string().notOneOf(['Оберіть опцію'], 'Выберите опцию').required('Выберите опцию'),
    file: Yup.mixed().required('Загрузите файл'),


});

const AddFeedbackModal = ({width, padding}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); // Состояние для отслеживания выбранного файла


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            phoneNumber: '',
            feedback: '',
            textarea: '',
            file: null,
            selectOption: 'Оберіть опцію',

        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
        },
    });

    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue("file", file);
        setSelectedFile(file); // Устанавливаем выбранный файл в состояние
    };

    return (
        <div style={{width: width}}>
            <Button className={classes.mainButton} onClick={handleOpen} style={{width: width, padding: padding}}>
                Залишити відгук
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Box>
                        <Box>
                            <Typography variant="h2" id="simple-modal-title" className={classes.title}>
                                Залишити відгук
                            </Typography>
                            <CloseIcon className={classes.closeButton} onClick={handleClose}/>
                        </Box>
                        <form className={classes.form}>
                            <Box className={classes.inputWrapper}>
                                <TextField
                                    label="Ваше ім’я*"
                                    name="firstName"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                                <TextField
                                    label="Телефон*"
                                    name="phoneNumber"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                />
                            </Box>
                            <Box style={{
                                width: '100%',
                                paddingBottom: '20px',
                                borderBottom: `1px solid ${colors.outerSpace}`
                            }}>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={4}
                                    placeholder="Ваш відгук*"
                                    name="textarea"
                                    className={classes.textArea}
                                    value={formik.values.textarea}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.textarea && Boolean(formik.errors.textarea)}
                                    helperText={formik.touched.textarea && formik.errors.textarea}
                                />
                            </Box>
                            <Box style={{width: '100%'}}>
                                <Typography className={classes.subTitle} variant="h3">
                                    Сортування
                                </Typography>
                                <Typography className={classes.description} varitant="h5">
                                    Оберіть послугу для якої ви залишаєте відгук
                                </Typography>
                                <Box className={classes.selectFileWrapper}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="selectOption"
                                        variant="outlined"
                                        IconComponent={() => <img src={ArrowDown} alt="arrow-right"
                                                                  className={classes.icon}/>}
                                        defaultValue="Оберіть опцію"
                                        className={classes.selectField}
                                        value={formik.values.selectOption}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.selectOption && Boolean(formik.errors.selectOption)}
                                        helperText={formik.touched.selectOption && formik.errors.selectOption}
                                    >
                                        <MenuItem value="Оберіть опцію" disabled className={classes.menuItem}>
                                            Оберіть послугу
                                        </MenuItem>
                                        <MenuItem value="Опція 1" className={classes.menuItem}>Leiter-3</MenuItem>
                                        <MenuItem value="Опція 2" className={classes.menuItem}>ADOS-2</MenuItem>
                                        <MenuItem value="Опція 3" className={classes.menuItem}>Аква-терапія</MenuItem>
                                    </Select>
                                    <Box className={classes.selectFileWrapper}>
                                        <Typography className={classes.subTitle} variant="h3">
                                            Фото
                                        </Typography>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            name="file"
                                            className={classes.fileInput}
                                            onChange={handleFileChange}
                                        />
                                        <label htmlFor="fileInput" className={classes.fileInputLabel}>
                                            {selectedFile ? selectedFile.name : "Обрати файл"}
                                        </label>
                                    </Box>
                                </Box>
                                </Box>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        className={classes.mainButton}
                                        disabled={!formik.isValid || formik.isSubmitting}
                                        onClick={formik.handleSubmit}
                                        style={{width: '100%'}}
                                    >
                                        Відправити
                                    </Button>
                                </div>
                        </form>
                    </Box>
                </div>
            </Modal>
        </div>
);
};

export default AddFeedbackModal;
