import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Icon} from "@material-ui/core";
import FeedbacksBanner from "../../../assets/img/banners/FeedbacksBanner.jpg";
import Banner from "../../UI/PhotoBanner";
import SectionWrapper from "../../UI/SectionWrapper";
import Typography from "@material-ui/core/Typography";
import {colors} from "../../../theme/default";
import StyledButton from "../../UI/StyledButton";
import Button from "@material-ui/core/Button";
import { ReactComponent as ArrowRight } from '../../../assets/icon/arrow-black-for-tabs.svg';
import CarerForm from "./CarerForm";
import CarerImageThird from "../../../assets/img/carer-image-3.jpg";
import CarerImageSecond from "../../../assets/img/carer-image-2.jpg";
import CarerImageFirst from "../../../assets/img/carer-image-1.jpg";
import CarerImage from "../../../assets/img/carer-image.jpg";


const useStyles = makeStyles((theme) => ({
    boxWrapper: {
        display: 'flex',
        gap: '5%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            gap: '32px',
        },
    },
    boxWrapperReverse: {
        display: 'flex',
        gap: '5%',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            gap: '32px',
        },
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        marginBottom: '100px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '60px',
            gap: '32px',
        },
    },
    photo: {
        width: '45%',
        borderRadius: '24px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    textBox: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    description: {
        color: `${colors.outerSpace}`,
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '18px',
        }
    },
    buttonWrapper: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        marginTop: '32px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            width: '100%',
        },
    },
    subTitle: {
        color: `${colors.outerSpace}`,
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '48px',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
            fontSize: '20px',
            lineHeight: '24px',
        }
    },
    tabsContainer: {
        marginBottom: '100px',
        padding: '0 10% 0 10%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '60px',
            padding: 'unset',
        },
    },
    tabsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '32px',
        gap: '2%',
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            rowGap: '10px',
            columnGap: '20px',
        },
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        borderRadius: '10px',
        padding: '12px 20px',
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        fontWeight: 400,
        border: `1px solid ${colors.outerSpace}`,
        lineHeight: '24px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: `${colors.outerSpace}`,
            '& .MuiButton-label': {
                color: `${colors.white}`,
            },
            '& $path': {
                transition: 'all 0.3s ease-in-out',
                fill: `${colors.white}`,
            },
        },
        '&.active': {
            backgroundColor: `${colors.outerSpace}`,
            '& .MuiButton-label': {
                color: `${colors.white}`,
            },
            '& $path': {
                transition: 'all 0.3s ease-in-out',
                fill: `${colors.white}`,
            },
        },
        '& .MuiButton-label': {
            color: `${colors.outerSpace}`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '18px',
            padding: '6px 8px',
            borderRadius: '4px',
            '& .MuiButton-label': {
                gap: '5px',
            },
            '&:hover': {
                backgroundColor: `${colors.outerSpace}`,
                '& .MuiButton-label': {
                    color: `${colors.white}`,
                },
            },
            '&.active': {
                backgroundColor: `${colors.congressBlue}`,
                borderColor: `${colors.congressBlue}`,
                '& .MuiButton-label': {
                    color: `${colors.white}`,
                },
            },
        },
    },
    subTitleTabContent: {
        color: `${colors.outerSpace}`,
        fontWeight: 500,
        fontSize: '28px',
        fontFamily: 'Inter-Bold',
        lineHeight: '34px',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '17px',
        }
    },
    descriptionTabContent: {
        color: `${colors.outerSpace}`,
        fontWeight: 400,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '18px',
        }
    },
    icon: {
        [theme.breakpoints.down('sm')]: {
            width: '10px',
            height: '10px',
        },
    },
    formTitle: {
        color: `${colors.outerSpace}`,
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '48px',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
            fontSize: '20px',
            lineHeight: '24px',
        }
    },
    textWrapper: {
        marginBottom: '60px',
        padding: '0 10% 0 10%',
        [theme.breakpoints.down('sm')]: {
            padding: 'unset',
        },
    },
    emailDescription: {
        color: `${colors.outerSpace}`,
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '18px',
        }
    },
}));

const CarerPage = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const tabContent = [
        {
            title: 'Крок 1',
            subTitle: 'Подача заявки',
            description:
                'Спершу вам потрібно надіслати своє резюме та мотиваційний лист на нашу електронну адресу.\n' +
                'Ми уважно розглянемо вашу кандидатуру та, якщо вона відповідатиме нашим вимогам,\n' +
                'зв’яжемося з вами для подальших кроків.\n',
        },
        {
            title: 'Крок 2',
            subTitle: 'Онлайн-співбесіда',
            description:
                'Якщо ваші документи пройдуть первинний відбір, вас запросять на онлайн-співбесіду. Це\n' +
                'можливість для нас ближче познайомитися з вами, а для вас – поставити запитання про роботу в\n' +
                'нашій клініці.\n',
        },
        {
            title: 'Крок 3',
            subTitle: 'Офлайн-співбесіда\n',
            description:
                'Після успішного проходження онлайн-співбесіди ми запросимо вас до клініки для особистої зустрічі.\n' +
                'Тут ви зможете побачити умови роботи, познайомитися з командою та пройти додаткові етапи\n' +
                'відбору.\n',
        },
        {
            title: 'Крок 4',
            subTitle: 'Стажування',
            description:
                'Наступний етап – це стажування в нашій клініці. Ви матимете змогу випробувати себе в\n' +
                'реальних умовах, показати свої навички та ознайомитися з нашими методиками роботи.\n',
        },
        {
            title: 'Крок 5',
            subTitle: 'Випробувальний термін\n',
            description:
                'Після успішного стажування вам буде запропоновано пройти випробувальний термін, протягом\n' +
                'якого ми зможемо оцінити вашу роботу, а ви зможете остаточно зрозуміти, чи підходить вам\n' +
                'наша клініка.\n',
        },
    ];

    return (
        <Box>
            <Banner
                backgroundImage={FeedbacksBanner}
                title='Приєднатися до нашої команди'
                description='Ви шукаєте кар’єру, а не просто роботу? Якщо так, то Space clinic може надати вам чудову можливість. Просто зайшовши на цю сторінку, ви зробили перший крок, але дозвольте нам розповісти вам трохи про себе, щоб ми могли переконатися, що ми підходимо один одному!'
            />
            <SectionWrapper marginBottom="100px">
                <Box className={classes.mainContent}>
                    <Box className={classes.boxWrapper}>
                        <img src={CarerImage} alt="" className={classes.photo}/>
                        <Box className={classes.textBox}>
                            <Typography variant='h3' className={classes.description}>
                                Наша реабілітаційна клініка для дітей з особливостями розвитку пишається тим, що має
                                професійну команду фахівців, які не лише володіють глибокими знаннями та досвідом, але й
                                справжньою пристрастю до своєї справи. Ми розуміємо, що наші пацієнти потребують уваги та
                                турботи, тому кожен співробітник клініки не лише висококваліфікований, а й дбайливий та
                                чуйний.
                            </Typography>
                            <Box className={classes.buttonWrapper}>
                                <StyledButton width="100%" text="Зв'яжіться з нами"/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.boxWrapperReverse}>
                        <img src={CarerImageFirst} alt="" className={classes.photo}/>
                        <Box className={classes.textBox}>
                            <Typography variant='h3' className={classes.description}>
                                У нашій клініці панує дружня та підтримуюча атмосфера. Ми впевнені, що для успішної роботи
                                важливо не лише професійне середовище, але й комфортне соціальне оточення. Ми підтримуємо
                                один одного, ділимося досвідом та допомагаємо новим співробітникам швидко адаптуватися.
                                Кожен, хто приєднується до нашої команди, стає частиною великої родини, де всі працюють на
                                спільну мету – допомогу дітям.
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.boxWrapper}>
                        <img src={CarerImageSecond} alt="" className={classes.photo}/>
                        <Box className={classes.textBox}>
                            <Typography variant='h3' className={classes.description}>
                                Ми надаємо широкі можливості для кар’єрного зростання. У нашій клініці регулярно проводяться
                                навчання, семінари та майстер-класи для підвищення кваліфікації. Ми заохочуємо розвиток, і
                                кожен наш співробітник має шанс рости разом з нами, досягаючи нових професійних висот.
                                Як приєднатися до нашої команди:
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.tabsContainer}>
                    <Typography variant="h2" className={classes.subTitle}>
                        Як виглядає процес співбесіди ?
                    </Typography>
                    <Box>
                        <Box className={classes.tabsWrapper}>
                            {tabContent.map((tab, index) => (
                                <Button
                                    key={index}
                                    className={`${classes.button} ${activeTab === index + 1 ? 'active' : ''}`}
                                    onClick={() => setActiveTab(index + 1)}
                                >
                                    <Icon
                                        component={ArrowRight}
                                        className={classes.icon}
                                        src={ArrowRight}
                                    />
                                    {tab.title}
                                </Button>
                            ))}
                        </Box>
                        <Typography className={classes.subTitleTabContent} variant="h2">
                            {tabContent[activeTab - 1].subTitle}
                        </Typography>
                        <Typography variant="h3" className={classes.descriptionTabContent}>
                            {tabContent[activeTab - 1].description}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.boxWrapper} style={{marginBottom: '60px'}}>
                    <img src={CarerImageThird} alt="" className={classes.photo}/>
                    <Box className={classes.textBox}>
                        <Typography variant="h2" className={classes.subTitle}>
                            Ми були б раді отримати звістки від вас!
                        </Typography>
                            <Typography variant='h3' className={classes.description}>
                                Приєднуйтесь до нашої команди та зробіть свій внесок у допомогу дітям з особливостями
                                розвитку!
                        </Typography>
                        <Box className={classes.buttonWrapper}>
                            <StyledButton width="100%" text="Зв'яжіться з нами"/>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.textWrapper}>
                    <Typography className={classes.emailDescription}>
                        Якщо у вас виникли питання щодо приєднання до команди, будь ласка, напишіть на tomatiskharkov54@gmail.com
                    </Typography>
                </Box>
                <Box>
                    <Typography className={classes.formTitle}>
                        Подайте заявку онлайн нижче
                    </Typography>
                    <CarerForm />
                </Box>
            </SectionWrapper>
        </Box>

    );
};

export default CarerPage;
