import React from "react";
import {useEffect} from "react";
import Banner from "./BannerSection";
import StaticInfoSection from "./StaticInfoSection";
import {Box} from "@material-ui/core";
import ReasonSection from "./ReasonsSection";
import ServicesSection from "./ServicesSection";
import StuffSection from "./StuffSection";
import AboutSection from "./AboutSection";
import FeedBackSection from "./FeedbacksSection";

const MainPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Banner/>
            <StaticInfoSection/>
            <ReasonSection/>
            <ServicesSection/>
            <StuffSection/>
            <AboutSection/>
            <FeedBackSection/>
        </Box>
    );
}

export default MainPage;