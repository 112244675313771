import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Link, Box } from '@material-ui/core';
import { colors } from '../../../theme/default';
import SectionWrapper from '../SectionWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    box: {
        display: 'grid',
        gridTemplateColumns: '30% 70%',
        gap: '5%',
    },
    tabs: {
        position: 'relative',
        '& .MuiTabs-flexContainerVertical': {
            alignItems: 'flex-start',
            gap: '60px',
            marginBottom: '60px',
        },
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .MuiTab-root': {
            padding: '0',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '& .MuiTab-wrapper': {
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'row-reverse',
        },
    },
    tab: {
        minWidth: 'auto',
        textTransform: 'none',
        cursor: 'pointer',
        fontFamily: 'Inter-Regular',
        fontSize: '60px',
        opacity: '0.7',
        fontWeight: '500',
        lineHeight: '73px',
        alignItems: 'flex-start',
        color: `${colors.outerSpace}`,
        textAlign: 'left',
        '&:hover': {
            textDecoration: 'none',
            opacity: '1',
            color: `${colors.tifanny}`,
        },
    },
    navigationLink: {
        textTransform: 'none',
        textDecoration: 'none',
        opacity: '0.8',
        cursor: 'pointer',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '400',
        marginBottom: '10px',
        color: `${colors.outerSpace}`,
        '&:hover': {
            textDecoration: 'none',
            opacity: '1',
            color: `${colors.tifanny}`,
        },
    },
    subTitleContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        //gap: '32px',
    },
    subTitleColumn: {
        gridColumn: 'auto',
        gridRow: 'auto',
    },
    staticLink: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '60px',
    },
    subTitle: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        textDecoration: 'none',
        marginBottom: '20px',
        cursor: 'pointer',
        transition: 'color 0.3s ease',
        '&:hover': {
            textDecoration: 'none',
            color: `${colors.tifanny}`,
        },
    },
    ul: {
        listStyle: 'none',
    },
}));

function NavigationTabs({ tabsData, linkData }) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [showSubtitles, setShowSubtitles] = useState(
        Array(tabsData.length).fill(false)
    ); // Создаем массив состояний для каждого подзаголовка

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setShowSubtitles(Array(tabsData.length).fill(false)); // Сбрасываем состояния при смене вкладки
    };

    const toggleSubtitle = (index) => {
        const updatedSubtitles = [...showSubtitles];
        updatedSubtitles[index] = !updatedSubtitles[index];
        setShowSubtitles(updatedSubtitles);
    };

    return (
        <div className={classes.root}>
            <SectionWrapper paddingTop="0" paddingBottom="100px">
                <div className={classes.box}>
                    <Box style={{ borderRight: `1px solid ${colors.outerSpace}` }}>
                        <Tabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            className={classes.tabs}
                        >
                            {tabsData.map((tab, index) => (
                                <Tab key={index} className={classes.tab} label={tab.label} />
                            ))}
                        </Tabs>
                        <Box className={classes.staticLink}>
                            {linkData.map((link, index) => (
                                <Link key={index} href={link.url} className={classes.tab}>
                                    {link.text}
                                </Link>
                            ))}
                        </Box>
                    </Box>
                    <div>
                        <div className={classes.subTitleContainer}>
                            <div className={classes.subTitleColumn}>
                                <ul className={classes.ul}>
                                    {tabsData[value].items
                                        .slice(0, 3) // Выбираем первые 3 подзаголовка для первого столбца
                                        .map((item, index) => (
                                            <li key={index} className={classes.li}>
                                                <Typography
                                                    variant="subtitle1"
                                                    className={classes.subTitle}
                                                    onClick={() => toggleSubtitle(index)} // Добавляем обработчик события клика для открытия/закрытия подзаголовка
                                                >
                                                    {item.subtitle}
                                                </Typography>
                                                {showSubtitles[index] && ( // Проверяем, открыт ли текущий подзаголовок
                                                    <ul
                                                        style={{
                                                            listStyle: 'none',
                                                            paddingLeft: '15px',
                                                            marginTop: '0',
                                                            marginBottom: '32px',
                                                        }}
                                                    >
                                                    {item.links.map((link, linkIndex) => (
                                                            <li key={linkIndex} style={{ marginBottom: '10px' }}>
                                                                <Link
                                                                    href={link.url}
                                                                    className={classes.navigationLink}
                                                                >
                                                                    {link.text}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div className={classes.subTitleColumn}>
                                <ul className={classes.ul}>
                                    {tabsData[value].items
                                        .slice(3, 5)
                                        .map((item, index) => (
                                            <li key={index} className={classes.li}>
                                                <Typography
                                                    variant="subtitle1"
                                                    className={classes.subTitle}
                                                    onClick={() => toggleSubtitle(index + 3)}
                                                >
                                                    {item.subtitle}
                                                </Typography>
                                                {showSubtitles[index + 3] && (
                                                    <ul
                                                        style={{
                                                            listStyle: 'none',
                                                            paddingLeft: '15px',
                                                            marginTop: '0',
                                                            marginBottom: '32px',
                                                        }}
                                                    >
                                                        {item.links.map((link, linkIndex) => (
                                                            <li key={linkIndex} style={{ marginBottom: '10px' }}>
                                                                <Link
                                                                    href={link.url}
                                                                    className={classes.navigationLink}
                                                                >
                                                                    {link.text}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionWrapper>
        </div>
    );
}

export default NavigationTabs;
