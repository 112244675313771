import React from "react";
import SectionWrapper from "../../../UI/SectionWrapper";
import Title from "../../../UI/H4";
import Grid from "@material-ui/core/Grid";
import ReviewCard from "../../../UI/FeedbackCard";
import FeedbackInTotalCard from "../../../UI/FeedbackInTotalCard";
import { useEffect, useState } from "react";
import FeedbackDB from "../../../../DB/feedback.json";

const feedbacks = FeedbackDB.slice(0, 5); // Обрезаем массив до первых 5 отзывов

function FeedBackSection() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [visibleCount, setVisibleCount] = useState(2);

    // Обновляем количество видимых отзывов в зависимости от ширины окна
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 600) {
                setVisibleCount(5); // На десктопе показываем все 5 отзывов
            } else {
                setVisibleCount(2); // На мобильных только 2
            }
        };

        // Добавляем слушатель изменения размера окна
        window.addEventListener("resize", handleResize);
        // Устанавливаем начальное количество видимых отзывов
        handleResize();

        return () => {
            // Удаляем слушатель при размонтировании компонента
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <SectionWrapper marginBottom="100px">
            <Title>Відгуки про нас</Title>
            <Grid container spacing={2}>
                {feedbacks.slice(0, visibleCount).map((feedback, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ReviewCard
                            review={feedback.review}
                            date={feedback.date}
                            name={feedback.name}
                            images={feedback.images}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4}>
                    <FeedbackInTotalCard />
                </Grid>
            </Grid>
        </SectionWrapper>
    );
}

export default FeedBackSection;

