import React from 'react';
import PageTitle from "../../../UI/H1";
import Description from "../../../UI/DescriptionText";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { colors } from "../../../../theme/default";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    subTitle: {
        fontSize: '32px',
        fontFamily: 'Inter-Regular',
        fontWeight: '400',
        lineHeight: '48px',
        marginBottom: '20px',
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '30px',
        },
    },
    accentText: {
        fontSize: '20px',
        fontFamily: 'Inter-Bold',
        fontWeight: '700',
        lineHeight: '30px',
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '21px',
        },
    }
}));

function StatePrograms() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PageTitle>
                Державні програми
            </PageTitle>
            <Typography className={classes.subTitle}>
                Ми працюємо по 309 постанові
            </Typography>
            <Typography className={classes.accentText}>
                Що таке 309 постанова?
            </Typography>
            <Description>
                Постанова Кабінету Міністрів України від 27.03.2019 № 309 "Про затвердження Порядку використання у 2019 році коштів, передбачених у державному бюджеті для здійснення реабілітації дітей з інвалідністю внаслідок дитячого церебрального паралічу.
            </Description>
        </>
    );
}

export default StatePrograms;