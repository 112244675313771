import React from 'react';
import PageTitle from "../../../UI/H1";
import Description from "../../../UI/DescriptionText";
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import BannerHistoryTest from '../../../../assets/img/BannerHistoryTest.jpg';
import {useEffect} from 'react';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 20px 0',
        },
    },
    roundedImage: {
        width: '100%',
        borderRadius: '36px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '18px',
        },
    },
    root: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
        },
    }
}));

function CompanyHistory() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box className={classes.root}>
            <Box>
                <PageTitle>
                    Історія Space clinic
                </PageTitle>
                <Description>
                    Серцем нашої клініки є Олена Євгенівна Кушнірська – фахівчиня з багаторічним досвідом, яка стала
                    натхненником і рушійною силою у створенні центру, де кожна дитина з особливостями розвитку отримує
                    максимально індивідуалізовану та ефективну допомогу. Олена Євгенівна має понад 26 років досвіду у
                    сфері психіатрії та 15 років у сфері реабілітації. Її глибокі знання та особливе ставлення до дітей
                    роблять її справжнім лідером, що надихає команду досягати нових вершин у своїй роботі.
                </Description>
                <br/>
                <Description>
                    Один з найяскравіших етапів нашої історії – реабілітація дітей на базі дельфінарію. Дельфінотерапія,
                    у поєднанні з апаратними процедурами, дала змогу досягти вражаючих результатів у лікуванні та
                    підтримці дітей з особливостями розвитку. Контакт з дельфінами допомагав дітям розкривати свій
                    внутрішній потенціал, покращувати емоційний стан і робити кроки до адаптації у суспільстві. Це був
                    унікальний досвід, що надихнув нас на подальше розширення можливостей нашої клініки.
                </Description>
                <br/>
                <Description>
                    З часом наші ініціативи продовжували зростати, і так народився маленький, але затишний центр
                    розвитку «Tomatis». Цей центр став місцем, де діти могли отримувати високоякісні заняття з
                    використанням методу Томатіс – передової технології, спрямованої на покращення мовленнєвих та
                    когнітивних здібностей. Ми пишаємося тим, що «Tomatis» став острівцем надії для багатьох родин, і ми
                    продовжуємо розвивати цей напрямок, враховуючи індивідуальні потреби кожної дитини.
                </Description>
                <br/>
                <Description>
                    Сьогодні наша історія триває у рамках клініки ментального та фізичного здоров'я «Space Clinic». Це
                    сучасний медичний заклад, де ми впроваджуємо новітні методики реабілітації та лікування дітей з
                    особливими потребами. «Space Clinic» стала символом нашого прагнення до нових висот, де кожна дитина
                    отримує комплексну підтримку для розвитку як ментального, так і фізичного
                    здоров'я. Наша мета – створити простір, де діти можуть рости, розвиватися і досягати своїх найкращих
                    результатів.
                    Таким чином, наша історія – це шлях постійного зростання та вдосконалення, який веде до того, щоб
                    кожна дитина, незалежно від своїх особливостей, змогла знайти своє місце у світі та жити повноцінним
                    життям.
                </Description>
            </Box>
            <Box className={classes.imageContainer}>
                <Box>
                    <img
                        src={BannerHistoryTest}
                        alt="BannerHistoryTest"
                        className={classes.roundedImage}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default CompanyHistory;
