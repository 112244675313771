import React, {useEffect} from "react";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../theme/default";
import StyledButton from "../../UI/StyledButton";
import CosmoMan from "../../../assets/icon/cosmo-man-icon.svg";
import Rocket from "../../../assets/icon/rocket-icon.svg";
import PlanetSystem from "../../../assets/icon/planet-system-icon.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundColor: `${colors.congressBlue}`,
    },
    title: {
        color: `${colors.white}`,
        fontFamily: 'Inter-Bold',
        fontSize: '240px',
        fontWeight: 700,
        lineHeight: '288px',
        marginBottom: '32px',
        [theme.breakpoints.down('md')]: {
            fontSize: '160px',
            lineHeight: '192px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '80px',
            lineHeight: '96px',
        },
    },
    subTitle: {
        color: `${colors.white}`,
        fontFamily: 'Inter-Bold',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '48px',
        marginBottom: '10px',
        [theme.breakpoints.down('md')]: {
            fontSize: '30px',
            lineHeight: '36px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    description: {
        color: `${colors.white}`,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
        marginBottom: '20px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            lineHeight: '19px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    textWrapperBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        width: '100%',
    },
    cosmoManPosition: {
        position: 'absolute',
        top: '15%',
        left: '10%',
        dropShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
        animation: 'cosmoManAnimation 3s infinite',
        [theme.breakpoints.down('md')]: {
            width: '100px',
            height: '100px',
            top: '20%',
            left: '10%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '80px',
            height: '80px',
            top: '25%',
            left: '10%',
        },
    },
    rocketPosition: {
        position: 'absolute',
        bottom: '0%',
        right: '1%',
        [theme.breakpoints.down('md')]: {
            width: '350px',
            height: '350px',
            bottom: '0%',
            right: '1%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '150px',
            height: '150px',
            bottom: '0%',
            right: '1%',
        },
    },
    planetSystemPosition: {
        width: '400px',
        height: '400px',
        position: 'absolute',
        top: '40%',
        left: '0%',
        [theme.breakpoints.down('md')]: {
            width: '300px',
            height: '300px',
            top: '50%',
            left: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '200px',
            height: '200px',
            top: '60%',
            left: '0%',
        },
    },
}));

function NotFoundPage() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box className={classes.root}>
            <style>{`
        @keyframes cosmoManAnimation {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-20px); /* Поднимаем на 20px */
          }
          100% {
            transform: translateY(0);
          }
        }
      `}</style>
            <img src={CosmoMan} alt="404" className={classes.cosmoManPosition} loading="lazy"/>
            <img src={Rocket} alt="404" className={classes.rocketPosition} loading="lazy"/>
            <img src={PlanetSystem} alt="404" className={classes.planetSystemPosition} loading="lazy"/>
            <Box className={classes.textWrapperBox}>
                <Typography variant="h1" className={classes.title}>
                    404
                </Typography>
                <Typography variant="h2" className={classes.subTitle}>
                    Упс! Сторінка не була знайдена :(
                </Typography>
                <Typography variant="h3" className={classes.description}>
                    Сторінка яку ви шукали не завантажилася, поверніться будь ласка до головної.
                </Typography>
                <StyledButton text="Повернутись" variant="defaultButton" to="/"/>
            </Box>
        </Box>
    );
}

export default NotFoundPage;
