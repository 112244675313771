import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReviewCard from '../../UI/FeedbackCard';
import FeedbackDB from '../../../DB/feedback.json';
import SectionWrapper from "../../UI/SectionWrapper";
import {colors} from "../../../theme/default";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LogoIcon from "../../../assets/icon/logo/Logo.svg";
import FeedbacksBanner from "../../../assets/img/banners/FeedbacksBanner.jpg";
import Banner from "../../UI/PhotoBanner";
import AddFeedbackModal from "../ModalWindow/AddFeedbackModal";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    loadMoreButton: {
        color: `${colors.white}`,
        backgroundColor: `${colors.tifanny}`,
        border: `1px solid ${colors.tifanny}`,
        borderRadius: '10px',
        padding: '10px 20px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        textTransform: 'none',
        boxShadow: 'none',

        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.tifanny}`,
            boxShadow: 'none',
            backgroundColor: 'transparent',
        },
        '&:disabled': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.white}`,
            boxShadow: 'none',
            backgroundColor: `${colors.tifanny}`,
            opacity: '0.5',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
            width: '100%'
        },
    },
    addFeedback: {
        display: 'flex',
        padding: '100px 0',
        gap: '20%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: '50px 0',
            gap: '50px',
        },
    },

    title: {
        fontSize: '80px',
        fontFamily: 'Inter-Regular',
        color: `${colors.outerSpace}`,
        fontWeight: 400,
        lineHeight: '97px',
        marginBottom: '40px',
    },

    boxWrapper: {
        position: 'relative',
        flex: '1 1 0',
    },
    logoIcon: {
        width: '70px',
        height: '70px',
        position: 'absolute',
        top: '-50px',
        left: '-60px',
        [theme.breakpoints.down('sm')]: {
            width: '50px',
            height: '50px',
            top: '-35px',
            left: '0px',
        },
    },
    subTitle: {
        fontSize: '48px',
        fontFamily: 'Inter-Regular',
        color: `${colors.outerSpace}`,
        fontWeight: 400,
        lineHeight: '58px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            lineHeight: '34px',
        },
    },
    description: {
        fontSize: '16px',
        fontFamily: 'Inter-Regular',
        color: `${colors.outerSpace}`,
        fontWeight: 400,
        lineHeight: '19px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
            paddingLeft: '35px',
        },
    },
    buttonWrapper: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

const ReviewsPage = () => {
    const classes = useStyles();
    const [reviews] = useState(FeedbackDB);
    const [visibleReviews, setVisibleReviews] = useState(8);
    const loadMoreStep = 8;

    const handleLoadMoreClick = () => {
        setVisibleReviews(visibleReviews + loadMoreStep);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isLoadMoreDisabled = visibleReviews >= reviews.length;

    return (
        <Box>
            <Banner
                backgroundImage={FeedbacksBanner}
                title='Відгуки'
                description='Відгуки наших клієнтів про наші послуги та сервіс. Якість та професіоналізм - наші головні пріоритети.'
            />
            <SectionWrapper>
                <Grid container spacing={3}>
                    {reviews.slice(0, visibleReviews).map((review, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <ReviewCard
                                index={review.index}
                                date={review.date}
                                name={review.name}
                                review={review.review}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Box className={classes.buttonContainer}>
                    <Button
                        className={classes.loadMoreButton}
                        variant="contained"
                        onClick={handleLoadMoreClick}
                        disabled={isLoadMoreDisabled}
                    >
                        Завантажити ще
                    </Button>
                </Box>
                <Box className={classes.addFeedback}>
                    <Box className={classes.boxWrapper}>
                        <Typography className={classes.subTitle}>
                            Допоможіть нам стати
                            краще - оцініть
                            якість послуг!
                        </Typography>
                    </Box>
                    <Box className={classes.boxWrapper}>
                        <img src={LogoIcon} alt="logo" className={classes.logoIcon}/>
                        <Typography className={classes.description}>
                            Поділіться вашим відгуком про наші послуги, і ви допоможете нам поліпшити сервіс до більш
                            високого рівня якості.
                        </Typography>
                        <Box className={classes.buttonWrapper}>
                            <AddFeedbackModal width="100%"/>
                        </Box>
                    </Box>

                </Box>
            </SectionWrapper>
        </Box>

    );
};

export default ReviewsPage;
