import React from 'react';
import PageTitle from "../../../UI/H1";
import Description from "../../../UI/DescriptionText";
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import MethodsBanner from '../../../../assets/img/about-methods.jpg';
import StyledButton from "../../../UI/StyledButton";
import {colors} from "../../../../theme/default";
import {useEffect} from 'react';

const methods = [
    {
        title: 'Акватерапія',
    },
    {
        title: 'Лікувальний масаж',
    },
    {
        title: 'Терапія невагомості',
    },
    {
        title: 'Терапія за методом Томатиса (вплив на різні частини головного мозку за допомогою аудіосигналів, таких як музика)',
    },
    {
        title: 'Логопедія та інші послуги',
    }
];

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: '32px 0 32px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0 20px 0',
        },
    },
    roundedImage: {
        width: '100%',
        borderRadius: '36px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '18px',
        },
    },
    listItem: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        fontWeight: '400',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    buttonWrapper: {
        width: '40%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        marginTop: '32px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '20px',
        },
    },
}));

function Methods() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PageTitle>
                Методи лікування
            </PageTitle>
            <Box>
                <Description>
                    Space Clinic - це сучасний центр реабілітації для дорослих та дітей, спеціалізація якого полягає в
                    фізіотерапевтичному та психолого-педагогічному впливі на організм, а також у роботі з тілом для
                    активізації компенсаторних можливостей.
                    Наша основна мета - забезпечити комплексний підхід до реабілітації дітей. Послуги нашого центру
                    включають в себе:
                    Фізіотерапія та реабілітація:
                </Description>
                {methods.map((method, index) => (
                    <li key={index} className={classes.listItem}>{method.title}</li>
                ))}
            </Box>
            <Box className={classes.imageContainer}>
                <Box>
                    <img
                        src={MethodsBanner}
                        alt="BannerHistoryTest"
                        className={classes.roundedImage}
                        loading="lazy"
                    />
                </Box>
            </Box>

            <Description>
                У нашому басейні завжди підтримується оптимальна температура для проведення "Акватерапії", що допомагає
                ефективно розслабитися та насолодитися процедурою.
                Крім просторого басейну, у нашій клініці є унікальне обладнання - іммерсіонна ванна.
            </Description>

            <Box className={classes.buttonWrapper}>
                <StyledButton text="Всі послуги та ціни" variant="defaultButton" width='100%' to="/services"/>
            </Box>
        </>
    );
}

export default Methods;