import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as ArrowServicesButton } from "../../../assets/icon/arrow-services.svg";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../theme/default";
import Box from "@material-ui/core/Box";
import {Icon} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    button: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '47px 0 47px 24px',
        backgroundColor: "transparent",
        border: 'none',
        borderBottom: `1px solid ${colors.outerSpace}`,
        boxShadow: 'none',
        borderRadius: '0',
        transition: '300ms ease-in-out',
        color: `${colors.outerSpace}`,
        "&:hover": {
            boxShadow: 'none',
            border: 'none',
            backgroundColor: "transparent",
            borderBottom: `1px solid ${colors.tifanny}`,
            "& $title, & $description": {
                color: `${colors.tifanny}`,
            },
            "& $arrowIcon": {
                "& path": {
                    transition: '300ms ease-in-out',
                    fill: `${colors.tifanny}`,
                }
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '28px 0',
        },
    },
    title: {
        fontSize: "20px",
        textTransform: 'none',
        fontWeight: '400',
        fontFamily: 'Inter-Regular',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        textAlign: 'left',
        transition: 'color 300ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
            lineHeight: '17px',
        },
    },
    description: {
        fontSize: "20px",
        textTransform: 'none',
        fontWeight: '400',
        fontFamily: 'Inter-Regular',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        width: '40%',
        textAlign: 'left',
        transition: 'color 300ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
            lineHeight: '17px',
            width: '20%',
        },
    },
    textWithIcon: {
        display: 'flex',
        width: '20%',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
        },
    },
    textBox: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
    arrowIcon: {
        width: '40px',
        transition: '300ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            transition: '300ms ease-in-out',
            width: '20px',
        },
    },
}));

function ServicesButton({title, description, titleSale, borderTop, ...props}) {
    const classes = useStyles();

    return (
        <Button className={classes.button} style={{borderTop: borderTop}} {...props}>
            <Box className={classes.textBox}>
                <Typography variant="body1" className={classes.title}>{title}</Typography>
                {titleSale ? <Typography variant="body1" className={classes.title}>{titleSale}</Typography> : null}
            </Box>
            <Box className={classes.textWithIcon}>
                <Typography variant="body2" className={classes.description}>{description}</Typography>
                {/*<ArrowRight className={classes.arrowIcon}/>*/}
                <Icon
                    component={ArrowServicesButton}
                    className={classes.arrowIcon}
                    src={ArrowServicesButton}
                />
            </Box>
        </Button>
    );
}

export default ServicesButton;