import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../theme/default';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    defaultButton: {
        color: `${colors.white}`,
        backgroundColor: `${colors.tifanny}`,
        border: `1px solid ${colors.tifanny}`,
        borderRadius: '10px',
        padding: '10px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        textTransform: 'none',

        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
            fontSize: '14px',
        },
    },
    outlinedButton: {
        color: `${colors.tifanny}`,
        backgroundColor: `${colors.white}`,
        border: `1px solid ${colors.tifanny}`,
        borderRadius: '10px',
        padding: '10px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        textTransform: 'none',

        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.white}`,
            backgroundColor: `${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
            fontSize: '14px',
        },
    },
    blackOutlinedButton: {
        color: `${colors.outerSpace}`,
        backgroundColor: 'transparent',
        border: `1px solid ${colors.outerSpace}`,
        borderRadius: '10px',
        padding: '10px',
        fontFamily: 'Inter-Regular',
        '& .MuiButton-label': {
            lineHeight: '24px',
            fontSize: '20px',
            fontWeight: '400',
            textTransform: 'none',

            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '17px',
            },
        },
        '&:hover': {
            border: `1px solid ${colors.outerSpace}`,
            color: `${colors.white}`,
            backgroundColor: `${colors.outerSpace}`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
        },
    }
}));

const StyledButton = ({ variant, text, width, to }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const getButtonStyle = () => {
        switch (variant) {
            case 'outlined':
                return classes.outlinedButton;
            case 'blackOutlined':
                return classes.blackOutlinedButton;
            default:
                return classes.defaultButton;
        }
    };

    const handleClick = () => {
        navigate(to);
    };

    return (
        <Button
            className={getButtonStyle()}
            variant={variant}
            style={{ width: width }}
            onClick={handleClick}
        >
            {text}
        </Button>
    );
};

export default StyledButton;

