import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../theme/default";
import Box from "@material-ui/core/Box";
import {Icon} from "@material-ui/core";
import {ReactComponent as ArrowServicesButton} from "../../../assets/icon/arrow-services.svg";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    button: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '60px 0 60px 20px',
        backgroundColor: "transparent",
        border: 'none',
        borderBottom: `1px solid ${colors.outerSpace}`,
        boxShadow: 'none',
        borderRadius: '0',
        transition: '300ms ease-in-out',
        color: `${colors.outerSpace}`,
        "&:hover": {
            boxShadow: 'none',
            border: 'none',
            backgroundColor: "transparent",
            borderBottom: `1px solid ${colors.tifanny}`,
            "& $title, & $description": {
                color: `${colors.tifanny}`,
            },
            "& $arrowIcon": {
                "& path": {
                    transition: '300ms ease-in-out',
                    fill: `${colors.tifanny}`,
                }
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 0',
        },
    },
    title: {
        fontSize: "32px",
        textTransform: 'none',
        fontWeight: '400',
        fontFamily: 'Inter-Regular',
        lineHeight: '38px',
        color: `${colors.outerSpace}`,
        width: '50%',
        textAlign: 'left',
        transition: 'color 300ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    description: {
        fontSize: "20px",
        textTransform: 'none',
        fontWeight: '400',
        fontFamily: 'Inter-Regular',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        width: '40%',
        textAlign: 'left',
        transition: 'color 300ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    mobileBoxView: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
            gap: '10px'
        },
    },
    desktopBoxView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    titleWithIcon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    arrowIcon: {
        width: '40px',
        transition: '300ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            transition: '300ms ease-in-out',
            width: '20px',
        },
    },
}));

function LongButton({title, description, link}) {
    const classes = useStyles();

    return (
        <Link to={link} style={{textDecoration: 'none'}}>
            <Button className={classes.button}>
                <Box className={classes.mobileBoxView}>
                    <Box className={classes.titleWithIcon}>
                        <Typography variant="body1" className={classes.title}>{title}</Typography>
                        <Icon
                            component={ArrowServicesButton}
                            className={classes.arrowIcon}
                            src={ArrowServicesButton}
                        />
                    </Box>
                    <Typography variant="body2" className={classes.description}>{description}</Typography>
                </Box>
                <Box className={classes.desktopBoxView}>
                    <Typography variant="body1" className={classes.title}>{title}</Typography>
                    <Typography variant="body2" className={classes.description}>{description}</Typography>
                    <Icon
                        component={ArrowServicesButton}
                        className={classes.arrowIcon}
                        src={ArrowServicesButton}
                    />
                </Box>
            </Button>
        </Link>
    );
}

export default LongButton;


