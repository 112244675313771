import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import {colors} from '../../../../theme/default';
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import {Box, Icon} from "@material-ui/core";
import {ReactComponent as ArrowIcon} from '../../../../assets/icon/arrow-right.svg';
import {Scrollbar} from 'react-scrollbars-custom';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '70%',
        borderRadius: '20px',
        backgroundColor: `${colors.white}`,
        border: 'none !important',
        outline: 'none !important',
        display: 'flex',
        flexDirection: 'column',
        gap: '60px',
        padding: '20px 20px 44px 20px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            gap: '40px',
            padding: '20px 20px 20px 20px',
        },
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        color: `${colors.outerSpace}`,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transition: 'all 0.3s ease-in-out',
            color: `${colors.tifanny}`,
        },
    },
    button: {
        backgroundColor: 'transparent',
        textTransform: "none",
        padding: "0",
        width: "fit-content",
        justifyContent: "flex-start",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "transparent",
            color: `${colors.tifanny}`,
            '& $path': {
                fill: `${colors.tifanny}`,
            },
        },

    },
    text: {
        fontSize: "20px",
        fontWeight: "400",
        fontFamily: "Inter-Regular",
        marginRight: "10px",
        color: `${colors.outerSpace}`,
        opacity: "0.8",
        '&:hover': {
            color: `${colors.tifanny}`,
            opacity: "1",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "17px",
        },
    },
    icon: {},

    title: {
        fontFamily: 'Inter-Regular',
        fontSize: '48px',
        lineHeight: '58px',
        fontWeight: '400',
        color: `${colors.outerSpace}`,
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '24px',
            width: '80%'
        },
    },
    reviewContainer: {
        maxHeight: '30vh',
        marginBottom: '32px',
    },
    date: {
        fontSize: "20px",
        fontWeight: "400",
        fontFamily: "Inter-Regular",
        lineHeight: "24px",
        marginBottom: "10px",
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "17px",
        },
    },
    name: {
        fontSize: "20px",
        fontWeight: "500",
        fontFamily: "Inter-Regular",
        lineHeight: "24px",
        marginBottom: "20px",
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "17px",
            marginBottom: "10px",
        },
    },
    review: {
        fontSize: "20px",
        fontWeight: "400",
        color: `${colors.outerSpace}`,
        fontFamily: "Inter-Regular",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        whiteSpace: "break-spaces",
        paddingBottom: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "17px",
        },
    },
}));

const FullFeedbackModal = ({width, padding, date, name, review}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{width: width}}>
            <Button
                className={classes.button}
                style={{width: width, padding: padding}}
                variant="contained"
                disableElevation
                onClick={handleOpen}
            >
                <Typography variant="body1" className={classes.text}>
                    Читати більше
                </Typography>
                <Icon component={ArrowIcon} className={classes.icon} src={ArrowIcon}/>
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Box>
                        <Box>
                            <Typography
                                variant="h2"
                                id="simple-modal-title"
                                className={classes.title}
                            >
                                Відгук
                            </Typography>
                            <CloseIcon className={classes.closeButton} onClick={handleClose}/>
                        </Box>
                        <Typography variant="body1" className={classes.date}>
                            {date}
                        </Typography>
                        <Typography variant="body1" className={classes.name}>
                            {name}
                        </Typography>
                        <div className={classes.reviewContainer}>
                            <Scrollbar
                                width={4}
                                style={{
                                    height: '30vh',
                                    marginBottom: '32px',
                                    backgroundColor: 'transparent',
                                }}
                                thumbYProps={{
                                    style: {
                                        backgroundColor: `${colors.congressBlue}`,
                                    },
                                }}
                                trackYProps={{
                                    style: {
                                        width: '4px',
                                    },
                                }}
                            >
                                <Typography variant="body1" className={classes.review}>
                                    {review}
                                </Typography>
                            </Scrollbar>
                        </div>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default FullFeedbackModal;
