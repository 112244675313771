import React, {useEffect} from 'react';
import StuffBackgroundTest from '../../../../assets/img/StuffBackgroundTest.jpg';
import {useLocation} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import Banner from "../../../UI/PhotoBanner";
import blogData from "../../../../DB/blogs.json";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import ContactModal from "../../ModalWindow/AppointmentModal";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 80,
        padding: '40px 15% 100px 15%',
        [theme.breakpoints.down('sm')]: {
            gap: 32,
            padding: '40px 20px 100px 20px',
        },
    },
    title: {
        fontFamily: 'Inter-Regular',
        fontSize: 40,
        fontWeight: 500,
        lineHeight: '48px',
        letterSpacing: '0em',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHeight: '24px',
        },
    },
    description: {
        fontFamily: 'Inter-Regular',
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        letterSpacing: '0em',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: '20px',
        },
    },
    mainBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        [theme.breakpoints.down('sm')]: {
            gap: 10,
        },
    },
    buttonWrapper: {
        width: '35%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

const PostPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const currentUrl = location.pathname;

    const selectedPost = blogData.find(blog => blog.url === currentUrl);

    // const selectedPost = blogData.find(blog => {
    //     console.log('currentUrl:', currentUrl);
    //     console.log('blog.url:', blog.url);
    //     return blog.url === currentUrl;
    // });


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Banner
                backgroundImage={selectedPost ? selectedPost.imageSrc : StuffBackgroundTest}
                height="70vh"
            />
            <Box className={classes.root}>
                <Box className={classes.mainBox}>
                    <Typography className={classes.title} variant="h1">
                        {selectedPost ? selectedPost.title : 'Услуга не найдена'}
                    </Typography>
                    <Typography
                        className={classes.description}
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: selectedPost ? selectedPost.titleDescription : 'Услуга не найдена'
                        }}
                    />
                </Box>
                <Box className={classes.mainBox}>
                    <Typography className={classes.title} variant="h1">
                        {selectedPost ? selectedPost.subTitle : 'Услуга не найдена'}
                    </Typography>
                    <Typography
                        className={classes.description}
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: selectedPost ? selectedPost.subTitleDescription : 'Услуга не найдена'
                        }}
                    />
                </Box>
                <Box className={classes.mainBox}>
                    <Typography className={classes.title} variant="h1">
                        {selectedPost ? selectedPost.thirdTitle : 'Услуга не найдена'}
                    </Typography>
                    <Typography
                        className={classes.description}
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: selectedPost ? selectedPost.thirdTitleDescription : 'Услуга не найдена'
                        }}
                    />
                </Box>
                <Box className={classes.mainBox}>
                    <Typography className={classes.title} variant="h1">
                        {selectedPost ? selectedPost.fourthTitle : 'Услуга не найдена'}
                    </Typography>
                    <Typography
                        className={classes.description}
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: selectedPost ? selectedPost.fourthTitleDescription : 'Услуга не найдена'
                        }}
                    />
                    <Box className={classes.buttonWrapper}>
                        <ContactModal buttonText="Записатися на прийом" width="100%"/>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default PostPage;