import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        fontWeight: '400',
        lineHeight: '24px',
        marginBottom: ({ marginBottom }) => marginBottom,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
}));

const Description = ({ children, color = '#1B2828', marginBottom, ...props }) => {
    const classes = useStyles();

    return (
        <Typography variant="h4" className={classes.title} style={{ color, marginBottom }} {...props}>
            {children}
        </Typography>
    );
};

export default Description;