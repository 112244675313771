import React from 'react';
import Banner from '../../UI/PhotoBanner';
import ServicesBanner from '../../../assets/img/banners/ServicecBackgroundBanner.jpg';
import TabMenu from "../../UI/TabMenu";
import SectionWrapper from "../../UI/SectionWrapper";
import {useEffect} from 'react';
import ServicesData from "../../../DB/services.json";

const tabsData = ServicesData;

const Services = () => {
    const pageTitle = 'Наші послуги та ціни';
    const pageDescription = 'Тут ви можете ознайомитися з усіма послугами, які ми надаємо, а також з їх вартістю.';
    const pageImage = ServicesBanner;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Banner
                backgroundImage={pageImage}
                title={pageTitle}
                description={pageDescription}
            />
            <SectionWrapper paddingBottom="100px">
                <TabMenu tabsData={tabsData}/>
            </SectionWrapper>
        </div>
    );
};

export default Services;
