import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import './styles.css';
import {Box} from '@material-ui/core';
//import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../theme/default";
import {Autoplay} from "swiper/modules";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            //height: '350px',
        },
    },
    swiperContainer: {
        transitionTimingFunction: 'unset',
        position: 'relative',
        width: '100%',
        height: '100%',
        zIndex: '1',
        display: 'block',
        transitionProperty: 'transform',
        boxSizing: 'content-box',
    },

    projectOverlay: {
        borderRadius: '0 0 19px 19px',
        backgroundColor: 'rgba(252, 251, 255, 0.8)',
        color: `${colors.tifanny}`,
        padding: '20px',
        opacity: '0',
        width: '100%',
        transition: 'opacity 0.3s ease-out',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
    },
    projectTitle: {
        fontFamily: 'Syncopate',
        textAlign: 'left',
        fontSize: '20px',
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    projectDesc: {
        userSelect: 'none',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },

}));


const Slider = ({projects, lineColor, slideComponent}) => {
    const classes = useStyles();

    const [progress, setProgress] = useState(0);

    const handleSlideChange = (swiper) => {
        const currentSlideIndex = swiper.activeIndex + 4;
        const totalSlides = swiper.slides.length;
        let progress;

        // Проверяем текущую ширину экрана и выбираем соответствующий подсчёт прогресса
        if (window.innerWidth >= 1024) {
            // Большие экраны: прогресс равен текущему слайду деленному на общее количество слайдов
            progress = currentSlideIndex / totalSlides;
        } else {
            // Мобильные экраны: прогресс равен текущему слайду деленному на общее количество слайдов минус 2
            // для компенсации смещения начала прогресс-полосы
            progress = (currentSlideIndex - 3) / totalSlides;
        }

        const clampedProgress = Math.min(Math.max(progress, 0), 1); // Ограничиваем значение прогресса от 0 до 1
        setProgress(clampedProgress);
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.swiperContainer}>
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        500: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                    slider="true"
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    watchSlidesProgress
                    onSlideChange={handleSlideChange}
                >
                    {projects.map((project) => (
                        <SwiperSlide key={project.id} className="project-card">
                            {slideComponent({ project })}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
            <Box style={{display: 'flex', justifyContent: 'center'}}>
                <Box
                    style={{
                        width: '100%',
                        height: '8px',
                        backgroundColor: 'transparent',
                        borderRadius: '10px',
                        border: '1px solid #024A81',
                        marginTop: '32px',
                    }}
                >
                    <div className="swiper-progress-bar" style={{width: `${progress * 100}%`}}/>
                </Box>
            </Box>
        </Box>
    );
};

export default Slider;