import React from 'react';
import Slider from '../../../UI/FullwidthSlider';
import ChildDoesNotSpeek from '../../../../assets/img/banners/ChildDoesNotSpeek.jpg';
import TechBg from '../../../../assets/img/main-banner-about-system.jpg';
import { useEffect } from 'react';
import InPacient from '../../../../assets/img/banners/inPacient.jpg';
import LicensesImage from '../../../../assets/img/licenses/licenses-bg.jpg';

const slides = [
    {
        image: ChildDoesNotSpeek,
        title: 'Дитина не розмовляє',
        description: 'Часто можна почути твердження, що якщо дитина не розмовляє до трьох років, це може бути нормою. Насправді, розвиток, включаючи мовленнєвий, у кожного малюка індивідуальний, проте не слід відкладати звернення за допомогою до фахівців до досягнення дитиною віку п\'яти років.',
        link: '/about/blog/the-child-does-not-speak',
    },
    {
        image: TechBg,
        title: 'Обладнання',
        description: 'На сьогоднішній день в нашому центрі використовуються сучасні методики та обладнання, які дозволяють проводити діагностику та корекцію порушень мовлення у дітей різного віку.',
        link: '/about/blog/our-equipment',
    },
    {
        image: LicensesImage,
        title: 'Сертифікати та ліцензії',
        description: 'Наш центр має ліцензію на надання медичних послуг, а також сертифікати на використання обладнання та методик, які використовуються в роботі.',
        link: '/about/blog/licenses',

    },
    {
        image: InPacient,
        title: 'Іногороднім пацієнтам',
        description: 'Наш центр надає послуги іногороднім пацієнтам. Для цього ви можете зв\'язатися з нами за телефоном або через форму зворотнього зв\'язку.',
        link: '/about/blog/for-non-residents',
    },
];

function BannerSection() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Slider slides={slides} />
        </div>
    );
}

export default BannerSection;
