import React from 'react';
import Banner from '../../UI/PhotoBanner';
import ServicesBanner from '../../../assets/img/banners/ServicecBackgroundBanner.jpg';
import SectionWrapper from "../../UI/SectionWrapper";
import {useEffect} from 'react';
import ImageOverlayCard from "../../UI/ServiceCard";
import BlogsData from "../../../DB/blogs.json";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

const blogs = BlogsData;

const Blog = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Banner
                backgroundImage={ServicesBanner}
                title="Блог"
                description="Тут ви можете ознайомитися з нашими статтями"
            />
            <SectionWrapper paddingBottom="100px">
                <Grid container spacing={3}>
                    {blogs.map((blog, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <Link to={blog.url} style={{textDecoration: 'none'}}>
                                <ImageOverlayCard
                                    key={index}
                                    height="100%"
                                    title={blog.title}
                                    description={blog.description}
                                    imageSrc={blog.imageSrc}
                                />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </SectionWrapper>
        </div>
    );
};

export default Blog;