import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../theme/default";
import { Box } from "@material-ui/core";
import FullFeedbackModal from "../../pages/ModalWindow/FullfeedbackModal";

const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
        borderRadius: "20px",
        boxShadow: "none",
        border: `1px solid ${colors.outerSpace}`,
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            borderRadius: "10px",
        },
    },
    cardContent: {
        flexGrow: 1,
    },
    date: {
        fontSize: "20px",
        fontWeight: "400",
        fontFamily: "Inter-Regular",
        lineHeight: "24px",
        marginBottom: "10px",
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "17px",
        },
    },
    name: {
        fontSize: "20px",
        fontWeight: "500",
        fontFamily: "Inter-Regular",
        lineHeight: "24px",
        marginBottom: "20px",
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "17px",
            marginBottom: "10px",
        },
    },
    review: {
        fontSize: "20px",
        fontWeight: "400",
        color: `${colors.outerSpace}`,
        fontFamily: "Inter-Regular",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 5,
        whiteSpace: "break-spaces",
        marginBottom: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "17px",
        },
    },
}));

function ReviewCard({ date, name, review }) {
    const classes = useStyles();

    return (
        <Box className={classes.card}>
            <Box className={classes.cardContent}>
                <Typography variant="body2" className={classes.date}>
                    {date}
                </Typography>
                <Typography variant="body1" className={classes.name}>
                    {name}
                </Typography>
                <Typography variant="body2" className={classes.review}>
                    {review}
                </Typography>
            </Box>
            <FullFeedbackModal date={date} name={name} review={review} />
        </Box>
    );
}

export default ReviewCard;


