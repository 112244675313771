import React from "react";
import SectionWrapper from "../../../UI/SectionWrapper";
import Title from "../../../UI/H4";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AboutImage from "../../../../assets/img/main-page-about-image.png";
import {Box} from "@material-ui/core";
import StyledButton from "../../../UI/StyledButton";
import {colors} from "../../../../theme/default";
import LongButton from "../../../UI/LongButton";
import {useEffect} from "react";

const info = [
    {
        title: "Обладнання",
        description: "Наш центр оснащений найсучаснішим медичним обладнанням експертного класу.",
        url: "/about/blog/our-equipment",
    },
    {
        title: "Сертифікати та ліцензії",
        description: "Для нас важливо щоб кожен пацієнт отримав високоякісну допомогу наших фахівців.",
        url: "/about/blog/licenses",
    },
    {
        title: "Іногороднім пацієнтам",
        description: "Інформація для іногородніх пацієнтів",
        url: "/about/blog/for-non-residents",
    },
];

const useStyles = makeStyles((theme) => ({
    aboutContainer: {
        display: "flex",
        gap: '52px',
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            gap: '20px',
        },
    },
    image: {
        width: "100%",
        height: "auto",
        borderRadius: "20px",
        [theme.breakpoints.down('sm')]: {
            borderRadius: "18px",
        },
    },
    textContainer: {
        flex: 1,
        width: "50%",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    title: {
        fontSize: "30px",
        fontWeight: "400",
        fontFamily: "Inter-Regular",
        lineHeight: "36px",
        color: `${colors.outerSpace}`,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
            marginBottom: 10,
            lineHeight: "19px",
        },
    },
    description: {
        fontSize: "20px",
        fontWeight: "400",
        fontFamily: "Inter-Regular",
        lineHeight: "24px",
        color: `${colors.outerSpace}`,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
            lineHeight: "17px",
            marginBottom: 20,
        },
    },
    buttonContainer: {
        display: "flex",
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: "row-reverse",
            gap: '10px',
        },
    },
    button: {
        color: "white",
        backgroundColor: "#00244C",
        border: "none",
        borderRadius: "10px",
        padding: "10px 20px",
        fontFamily: "Inter-Regular",
        fontSize: "16px",
        lineHeight: "1.5",
        fontWeight: "400",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#001E3C",
        },
    },
}));

function AboutSection() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <SectionWrapper>
            <Title>Що потрібно знати о Space clinic</Title>
            <Box className={classes.aboutContainer}>
                <Box style={{flex: 1}}>
                    <img
                        src={AboutImage}
                        alt="О нас"
                        className={classes.image}
                    />
                </Box>
                <Box className={classes.textContainer}>
                    <Typography variant="h6" className={classes.title}>
                        Space Clinic - реабілітація дорослих і дітей.
                    </Typography>
                    <Typography variant="body1" className={classes.description}>
                        Ми надаємо високоякісні послуги у галузі логопедії та спеціальної
                        психології з більш ніж 10-річним досвідом. Наша команда
                        професіоналів готова вам допомогти в розв'язанні різних
                        проблем та питань щодо мовлення та психологічного стану.
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <StyledButton variant="defaultButton" text={"Послуги"} width="50%" to={"/services"}/>
                        <StyledButton variant="blackOutlined" text={"Більше про Space Clinic"} width="50%" to={"/about"}/>
                    </div>
                </Box>
            </Box>
            <Box>
                {info.map((item, index) => (
                    <LongButton key={index} title={item.title} description={item.description} link={item.url}/>
                ))}
            </Box>
        </SectionWrapper>
    );
}

export default AboutSection;
