import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {colors} from '../../../theme/default';
import SectionWrapper from "../SectionWrapper";
import Breadcrumbs from "../Breadcrumbs";



const useStyles = makeStyles((theme) => ({
    banner: {
        width: '100%',
        height: (props) => props.height || '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    content: {
        position: 'relative',
        gap: '45%',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px',
            gap: '40%',
        },
    },
    breadcrumb: {
        textAlign: 'left',
    },
    title: {
        fontSize: '80px',
        fontFamily: 'Inter-Regular',
        color: `${colors.white}`,
        fontWeight: 500,
        fontHeight: '97px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            fontHeight: '34px',
            letterSpacing: '0em',
            textAlign: 'left',
            marginBottom: '10px',
        },
    },
    description: {
        color: `${colors.white}`,
        fontFamily: 'Inter-Regular',
        fontWeight: 400,
        width: '50%',
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
            textAlign: 'left',
            width: '100%'
        },
    },
}));

const Banner = ({ backgroundImage, title, description, height }) => {
    const classes = useStyles({ height }); // height is not used
    return (
        <div
            className={classes.banner}
            style={{backgroundImage: `url(${backgroundImage})`}}
        >
            <div className={classes.overlay}></div>
            <SectionWrapper backgroundColor="transparent" height="70%">
            <Box className={classes.content}>
                <Breadcrumbs/>
                <Box>
                    <Typography variant="h1" className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant="h3" className={classes.description}>
                        {description}
                    </Typography>
                </Box>
            </Box>
            </SectionWrapper>
        </div>
    );
};

export default Banner;
