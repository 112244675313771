import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../theme/default';
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    sidebar: {
        position: 'sticky',
        top: '125px',
        height: '100vh',
        backgroundColor: 'transparent',
    },
    linkButton: {
        backgroundColor: 'transparent',
        alignItems: 'flex-start',
        padding: '0',
        '&:hover': {
            backgroundColor: 'transparent',
            color: `${colors.tifanny}`,
        },
    },
    linkText: {
        fontSize: '28px',
        fontFamily: 'Inter-Regular',
        fontWeight: 500,
        lineHeight: '42px',
        padding: '0',
        color: `${colors.outerSpace}`,
        textTransform: 'none',
        '&:hover': {
            color: `${colors.tifanny}`,
        },
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'flex-start',
    },
}));

const Sidebar = ({ sections }) => {
    const classes = useStyles();
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const header = document.querySelector('#root > header > div');
        if (header) {
            setHeaderHeight(header.clientHeight + 30);
        }

        const handleScroll = () => {
            const currentScrollPos = window.scrollY + headerHeight;
            sections.forEach((section) => {
                const sectionElement = document.getElementById(section.id);
                if (sectionElement) {
                    const sectionTop = sectionElement.offsetTop;
                    const sectionBottom = sectionTop + sectionElement.clientHeight;
                    if (currentScrollPos >= sectionTop && currentScrollPos < sectionBottom) {
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sections, headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop - headerHeight,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Box className={classes.sidebar}>
            <Box className={classes.flexBox}>
                {sections.map((section) => (
                    <Button
                        key={section.id}
                        className={classes.linkButton}
                        onClick={() => scrollToSection(section.id)}
                    >
                        <Typography variant="h6" color="inherit" className={classes.linkText}>
                            {section.label}
                        </Typography>
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default Sidebar;



