import React from "react";
import SectionWrapper from "../../../UI/SectionWrapper";
import Title from "../../../UI/H4";
import EmployeeCard from "../../../UI/StuffCard";
import Grid from "@material-ui/core/Grid";
import StyledButton from "../../../UI/StyledButton";
import {Box} from "@material-ui/core";
import StuffMainPage from '../../../../DB/stuff-full-list.json';
import {useEffect} from "react";
import Slider from "../../../UI/Slider";
import {colors} from "../../../../theme/default";
import {makeStyles} from "@material-ui/core/styles";

const employees = StuffMainPage.slice(0, 4);

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        width: '25%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    sliderBox: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '32px',
        },
    },
    employeeCard: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

function StuffSection() {
    const classes = useStyles();
    const firstGroup = employees.slice(0, 2);
    const secondGroup = employees.slice(2, 4);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <SectionWrapper>
            <Title>Наші фахівці</Title>
            <Grid container spacing={2} style={{marginBottom: '32px'}} className={classes.employeeCard}>
                {/* Группа 1 */}
                {firstGroup.map((employee, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <EmployeeCard
                            to={employee.url}
                            firstName={employee.firstName}
                            lastName={employee.lastName}
                            middleName={employee.middleName}
                            specialization={employee.specialization}
                            description={employee.description}
                            imageSrc={employee.imageSrc}
                        />
                    </Grid>
                ))}
                {/* Группа 2 */}
                {secondGroup.map((employee, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <EmployeeCard
                            to={employee.url}
                            firstName={employee.firstName}
                            lastName={employee.lastName}
                            middleName={employee.middleName}
                            specialization={employee.specialization}
                            description={employee.description}
                            imageSrc={employee.imageSrc}
                        />
                    </Grid>
                ))}
            </Grid>
            <div className={classes.sliderBox}>
                <Slider
                    projects={employees}
                    lineColor={`${colors.tifanny}`}
                    slideComponent={({project}) => (
                        <EmployeeCard
                            to={project.url}
                            firstName={project.firstName}
                            lastName={project.lastName}
                            middleName={project.middleName}
                            specialization={project.specialization}
                            description={project.description}
                            imageSrc={project.imageSrc}
                        />
                    )}
                />
            </div>
            <Box className={classes.buttonWrapper}>
                <StyledButton text="Усі фахівці" variant="outlined" width='100%' to="/stuff"/>
            </Box>
        </SectionWrapper>
    );
}

export default StuffSection;
