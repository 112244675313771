import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton, Drawer, Box} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from "@material-ui/icons/Close";
import {colors} from '../../../theme/default';
import './styles.css'
import NavigationTabs from "../../UI/NavigationTabs";
import MenuData from "../../../DB/menu.json";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../../assets/icon/logo/Logo.svg";
import AppBar from "@material-ui/core/AppBar";
import MobileToggleMenu from "../../UI/MobileToggleMenu";
import ContactModal from "../../pages/ModalWindow/AppointmentModal";

const tabsData = MenuData;

const linkData = [
    {url: '/services', text: 'Ціни'},
    {url: '/stuff', text: 'Фахівці'},
    {url: '/about/gallery', text: 'Галерея'},
    {url: '/carer', text: 'Кар’єра'},
];


const useStyles = makeStyles((theme) => ({
    menuButton: {
        padding: '0',
        backgroundColor: 'transparent',
        color: (props) => (props.scrolled ? `${colors.outerSpace}` : `${colors.white}`),
        transition: 'color 0.5s ease',
        '&:hover': {
            backgroundColor: 'transparent',
            color: `${colors.tifanny}`,
        }
    },
    list: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
    },
    drawer: {
        width: '100%',
        height: '100%',
        backgroundColor: `${colors.white}`,
        borderRadius: '0',
        position: 'fixed',
        top: 0,
        zIndex: 9999,
    },
    navBlock: {
        color: `${colors.outerSpace}`,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    navigationText: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: 400,
        color: `${colors.outerSpace}`,
        textTransform: 'none',
        '&:hover': {
            color: `${colors.tifanny}`,
        },
    },
    closeIcon: {
        color: `${colors.outerSpace}`,
        backgroundColor: 'transparent',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'transparent',
            color: `${colors.tifanny}`,
        },
    },
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        transition: 'all 0.7s ease-out',
    },
    toolbar: {
        border: '1px solid transparent',
        padding: '10px 40px',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '20px 100px 20px 100px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '20px 150px 20px 150px',
        },
    },
    logo: {
        width: '60px',
        height: '60px',
    },
    buttonWrapper: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

function ToggleMenu({menuOpen, setMenuOpen}) {
    const [scrolled, setScrolled] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [open, setOpen] = useState(false);
    const [menuIcon, setMenuIcon] = useState('menu');
    const classes = useStyles({ scrolled });

    const toggleDrawer = () => {
        setOpen(!open);
        setMenuOpen(!menuOpen);
        if (!open) {
            document.body.style.position = 'fixed';
        } else {
            document.body.style.position = 'relative';
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Tab' || event.key === 'Escape') {
            event.preventDefault();
        }
    };

    const handleMenuIconChange = () => {
        setMenuIcon((prevIcon) => (prevIcon === 'menu' ? 'close' : 'menu'));
        toggleDrawer();
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
            setScrolled(currentScrollPos > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, visible]);


    const PseudoHeader = () => (
        <AppBar className={classes.appBar} position="static">
            <Toolbar className={classes.toolbar}>
                <Box>
                    <img src={Logo} alt="logo" className={classes.logo}/>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '34px'}}>
                    <Box className={classes.buttonWrapper}>
                        <ContactModal buttonText="Консультація" titleText="Запис на консультацію"/>
                    </Box>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        aria-label="menu"
                        onClick={handleMenuIconChange}
                    >
                        {menuIcon === 'menu' ? <MenuIcon/> : <CloseIcon className={classes.closeIcon}/>}
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );

    const list = () => (
        <div className={classes.list} role="presentation">
            <PseudoHeader/>
            <NavigationTabs tabsData={tabsData} linkData={linkData}/>
            <MobileToggleMenu tabsData={tabsData} linkData={linkData}/>
        </div>
    );

    return (
        <div>
            <IconButton
                edge="start"
                className={classes.menuButton}
                aria-label="menu"
                onClick={handleMenuIconChange}
            >
                {menuIcon === 'menu' ? <MenuIcon/> : <CloseIcon className={classes.closeIcon}/>}
            </IconButton>
            <Drawer
                anchor="right"
                open={open}
                onClose={toggleDrawer}
                classes={{paper: classes.drawer}}
                onKeyDown={handleKeyDown}
            >
                {list()}
            </Drawer>
        </div>
    );
}

export default ToggleMenu;
