import React, {useState} from 'react';
import SwiperCore from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {colors} from '../../../theme/default';
import {Pagination} from "swiper/modules";
import {ReactComponent as ArrowRightButton} from '../../../assets/icon/arrow-right-button.svg';
import {Box, Icon} from "@material-ui/core";
import {Autoplay} from "swiper/modules";

SwiperCore.use([Pagination, Autoplay]);

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 40,
        height: '100%',
        color: 'white',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            marginTop: '15%',
            padding: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '40px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '40px 150px 0 150px'
        }
    },
    button: {
        borderRadius: '50%',
        width: '60px !important',
        height: '60px !important',
        display: 'flex',
        minWidth: '60px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        backgroundColor: `${colors.tifanny}`,
        border: `2px solid ${colors.tifanny}`,
        transition: 'all 0.3s ease-in-out',

        '&:hover': {
            transition: 'all 0.3s ease-in-out',
            backgroundColor: `transparent`,
            border: `2px solid ${colors.tifanny}`,
            '& $path': {
                transition: 'all 0.3s ease-in-out',
                fill: `${colors.tifanny}`,
            },
        },
    },
    root: {
        width: '100%',
        height: '100vh',
    },
    title: {
        zIndex: 1,
        fontWeight: '500',
        fontFamily: 'Inter-Regular',
        fontSize: '80px',
        textWrap: 'nowrap',
        color: 'white',
        marginBottom: '20px',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            fontSize: '60px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            marginBottom: '10px'
        },
    },
    description: {
        zIndex: 1,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '500',
        color: 'white',
        textAlign: 'left',
        width: '50%',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            width: '100%',
        },
    },
    pagination: {
        marginLeft: '20px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: '10px',
        zIndex: 1,
        clickable: false,
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
            gap: '40px',
        },
    },
    paginationItem: {
        borderRadius: "50%",
        width: 10,
        height: 10,
        backgroundColor: "#fff",
        opacity: 0.5,
        clickable: false,
        transition: "opacity 0.3s ease",
        [theme.breakpoints.down('sm')]: {
            width: 8,
            height: 8,
        },
    },
    paginationItemActive: {
        opacity: 1,
        backgroundColor: `${colors.tifanny}`,
    },
    background: {
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
    },
    backgroundImage: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: 'brightness(0.6)',
        zIndex: 0,
    },
    icon: {
        height: '16px',
        width: '27px',
    },
    flexBox: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '5%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    buttonWithPaginnationBox: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            width: '100%',
            justifyContent: 'space-between',
        },
    },
}));

function Slider({slides}) {
    const classes = useStyles();
    const [activeSlide, setActiveSlide] = useState(0);

    const handleSlideChange = (swiper) => {
        const {activeIndex} = swiper;
        setActiveSlide(activeIndex);
        swiper.slideTo(activeIndex);
    };


    return (
        <Swiper
            className={classes.root}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{delay: 6000}}
            pagination={false}
            onSlideChange={handleSlideChange}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index} className={classes.background}>
                    <div
                        className={classes.backgroundImage}
                        style={{ backgroundImage: `url(${slide.image})` }}
                    />
                    <div className={classes.content}>
                        <Typography variant="h4" className={classes.title}>
                            {slide.title}
                        </Typography>
                        <Box className={classes.flexBox}>
                            <Typography variant="body1" className={classes.description}>
                                {slide.description}
                            </Typography>
                            <Box className={classes.buttonWithPaginnationBox}>
                                <Button className={classes.button} as="a" href={slide.link}>
                                    <Icon
                                        component={ArrowRightButton}
                                        className={classes.icon}
                                        src={ArrowRightButton}
                                    />
                                </Button>
                                <div className={classes.pagination}>
                                    {slides.map((_, i) => (
                                        <div
                                            key={i}
                                            className={`${classes.paginationItem} ${
                                                i === activeSlide ? classes.paginationItemActive : ""
                                            }`}
                                        />
                                    ))}
                                </div>
                            </Box>
                        </Box>
                    </div>
                </SwiperSlide>

            ))}
        </Swiper>
    );
}

export default Slider;
