import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ContactForm from "../../UI/ContactForm";
import SectionWrapper from "../../UI/SectionWrapper";
import Title from "../../UI/H4";
import {colors} from "../../../theme/default";
import {Box} from "@material-ui/core";
import {useEffect} from "react";
import './styles.css';

const useStyles = makeStyles((theme) => ({
    footerContent: {
        display: "flex",
        gap: '5%',
        alignItems: "flex-start",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            gap: '40px',
        },
    },
    contactInfo: {},
    socialLinks: {
        display: "flex",
        flexDirection: "row",
        gap: '20px',
        "& a": {
            color: `${colors.white}`,
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',
            textDecoration: "none",
            paddingBottom: '0',
            borderBottom: `1px solid ${colors.white}`,
            display: "block",
            transition: 'all 0.5s ease',
            '&:hover': {
                color: `${colors.tifanny}`,
                borderBottom: `1px solid ${colors.tifanny}`,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '17px',
            },
        },
    },
    navigationLinks: {
        display: "flex",
        flexDirection: "row",
        "& a": {
            color: `${colors.white}`,
            fontFamily: 'Inter-Regular',
            fontSize: '16px',
            textDecoration: "none",
            marginBottom: '16px',
            transition: 'all 0.5s ease',
            "&:hover": {
                color: `${colors.tifanny}`,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '15px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: "row",
            flexWrap: "wrap",
            gap: '20%',
        },
    },
    sectionWrapper: {
        flex: '1 1 0',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: `${colors.white}`,
        opacity: '0.6',
        fontFamily: 'Inter-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    description: {
        color: `${colors.white}`,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '24px',
        textDecoration: "none",
        transition: 'all 0.5s ease',
        '&:hover': {
            color: `${colors.tifanny}`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    contactFormWrapper: {
        width: '33%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    mainContentWrapper: {
        width: '66%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    contactSocialWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '16px',
        },
    },
    titleBlockWrapper: {
        width: '25%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    titleNavigation: {
        color: `${colors.white}`,
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
}));

function Footer() {
    const classes = useStyles();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <SectionWrapper backgroundColor='#001A2E' paddingBottom="100px">
            <Title color={`${colors.white}`}>Зв’яжіться з нами</Title>
            <Box>
                <Box className={classes.footerContent}>
                    <Box className={classes.contactFormWrapper}>
                        <ContactForm theme="default"/>
                    </Box>
                    <Box className={classes.mainContentWrapper}>
                        <Box className={classes.contactInfo}>
                            <Box className={classes.contactSocialWrapper}>
                                <Box className={classes.titleBlockWrapper}>
                                    <Typography variant="h6" className={classes.title}>Адреса кліники</Typography>
                                    <a
                                        className={classes.description}
                                        href="https://maps.app.goo.gl/k1VndSVNPhsUSVKG8"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        вулиця Шевченка, 133, Харків, Харківська область
                                    </a>
                                </Box>
                                <Box className={classes.titleBlockWrapper}>
                                    <Typography variant="h6" className={classes.title}>Ми в соціальних
                                        мережах</Typography>
                                    <div className={classes.socialLinks}>
                                        <a href={"https://www.instagram.com/space_clinic/"}>Inst</a>
                                        <a href={"https://www.facebook.com/spaceclinickh/"}>FB</a>
                                        <a href={"https://www.twitter.com/"}>Telegram</a>
                                    </div>
                                </Box>
                                <Box className={classes.titleBlockWrapper}>
                                    <Typography variant="h6" className={classes.title}>Номер телефона:</Typography>
                                    <Typography className={classes.description}>
                                        +380 (50) 520 00 13
                                    </Typography>
                                </Box>
                            </Box>
                            <div className={classes.navigationLinks}>
                                <div className={classes.sectionWrapper}>
                                    <a href='/services' className={classes.titleNavigation}>Послуги</a>
                                    <a href='/services'>Діагностика</a>
                                    <a href='/services'>Терапія</a>
                                    <a href='/services'>Консультації та медична підтримка</a>
                                    <a href='/services'>Реабілітація</a>
                                    <a href='/services'>Інші послуги</a>
                                </div>
                                <div className={classes.sectionWrapper}>
                                    <a href='/about' className={classes.titleNavigation}>О нас</a>
                                    <a href='/about'>О Space clinic</a>
                                    <a href='/about/blog'>Блог</a>
                                </div>
                                <div className={classes.sectionWrapper}>
                                    <a href='/services' className={classes.titleNavigation}>Ціни</a>
                                    <a href='/services'>Діагностика</a>
                                    <a href='/services'>Терапія</a>
                                    <a href='/services'>Консультації та медична підтримка</a>
                                    <a href='/services'>Реабілітація</a>
                                    <a href='/services'>Інші послуги</a>
                                </div>
                                <Box className={classes.sectionWrapper} style={{gap: '32px'}}>
                                    <Box>
                                        <a href='/stuff' className={classes.titleNavigation}>Фахівці</a>
                                    </Box>
                                    <Box>
                                        <a href='/contacts' className={classes.titleNavigation}>Контакти</a>
                                    </Box>
                                    <Box>
                                        <a href='/about/feedbacks' className={classes.titleNavigation}>Відгуки</a>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </SectionWrapper>
    );
}

export default Footer;
