import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ArrowRightForLink from '../../../assets/icon/ArrowRightForLink.svg';
import { colors } from '../../../theme/default';
import translations from '../../../DB/translations.json';
import { Box, Link } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        marginBottom: '40px',
        //paddingLeft: '15%',
        margin: '20px 0 40px 0',
    },
    useLocationLink: {
        fontSize: '16px',
        fontWeight: 400,
        color: `${colors.white}`,
        textTransform: 'none',
        textDecoration: 'none',
        fontFamily: 'Inter-Regular',
        '&:hover': {
            color: `${colors.tifanny}`,
            textDecoration: 'none',
        }
    },
    useLocationLinkNow: {
        fontSize: '16px',
        fontWeight: 400,
        color: `${colors.tifanny}`,
        textTransform: 'capitalize',
        textDecoration: 'none',
        fontFamily: 'Inter-Regular',

        '&:hover': {
            textDecoration: 'none',
        }

    },
}));

const translateSegment = (segment) => {
    const language = 'uk';
    if (translations[segment] && translations[segment][language]) {
        return translations[segment][language];
    } else {
        return segment;
    }
};

const Breadcrumbs = () => {
    const location = useLocation();
    const classes = useStyles();

    const createBreadcrumbs = () => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const breadcrumbs = [];
        let currentPath = '';

        breadcrumbs.push(
            <span key="home">
                <Link component={RouterLink} to="/" className={classes.useLocationLink}>
                    {translateSegment('home')}
                </Link>
            </span>
        );

        pathSegments.forEach((segment, index) => {
            currentPath += `/${segment}`;
            const translatedSegment = translateSegment(segment);
            const isLastSegment = index === pathSegments.length - 1;

            breadcrumbs.push(
                <span key={currentPath}>
                    {' '}
                    <img src={ArrowRightForLink} alt="ArrowRightForLink" className={classes.arrowBlack} loading="lazy"/>
                    {' '}
                    <Link
                        className={isLastSegment ? classes.useLocationLinkNow : classes.useLocationLink}
                        component={RouterLink}
                        to={currentPath}
                        color="inherit"
                    >
                        {translatedSegment}
                    </Link>
                </span>
            );
        });

        return breadcrumbs;
    };

    return (
        <Box className={classes.breadcrumb}>
            {createBreadcrumbs()}
        </Box>
    );
};

export default Breadcrumbs;