import React from 'react';
import { Helmet } from 'react-helmet';

const RichLink = ({ title, description, image }) => {
    return (
        <Helmet>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={title} />
            <meta property="og:image:width" content="1359" />
            <meta property="og:image:height" content="1282" />
            <meta property="og:image:aspect_ratio" content="1359:1282" />
        </Helmet>
    );
};

export default RichLink;
