import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '54px',
        fontFamily: 'Inter-Bold',
        fontWeight: '700',
        lineHeight: '81px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            lineHeight: '48px',
            marginBottom: '10px',
        },
    },
}));

const PageTitle = ({ children, color = '#1B2828', ...props }) => {
    const classes = useStyles();

    return (
        <Typography variant="h4" className={classes.title} style={{ color }} {...props}>
            {children}
        </Typography>
    );
};

export default PageTitle;