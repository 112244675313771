import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import {colors} from '../../../../theme/default';
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import {Box, Icon} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useFormik} from "formik";
import * as Yup from "yup";

import { ReactComponent as InstagramIcon } from '../../../../assets/icon/instagram-icon.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/icon/telegram-icon.svg';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '70%',
        borderRadius: '20px',
        backgroundColor: `${colors.white}`,
        border: 'none !important',
        outline: 'none !important',
        display: 'flex',
        flexDirection: 'column',
        gap: '60px',
        padding: '20px 20px 44px 20px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            gap: '40px',
            padding: '20px 20px 20px 20px',
        },
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        color: `${colors.outerSpace}`,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transition: 'all 0.3s ease-in-out',
            color: `${colors.tifanny}`,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    input: {
        width: '100%',
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    socialButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',

    },
    socialButton: {
        width: '100%',
        padding: '10px 20px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        backgroundColor: 'transparent',
        border: `1px solid ${colors.outerSpace}`,
        borderRadius: '10px',
        color: `${colors.outerSpace}`,
        textTransform: 'none',
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '25px',
            [theme.breakpoints.down('sm')]: {
                gap: '10px',
            },
        },
        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.tifanny}`,
            backgroundColor: 'transparent',
            transition: 'all 0.3s ease-in-out',
            '& $path': {
                transition: 'all 0.3s ease-in-out',
                fill: `${colors.tifanny}`,
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
            fontSize: '14px',
        },
    },
    mainButton: {
        color: `${colors.white}`,
        backgroundColor: `${colors.tifanny}`,
        border: `1px solid ${colors.tifanny}`,
        borderRadius: '10px',
        padding: '10px',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        textTransform: 'none',

        '&:hover': {
            border: `1px solid ${colors.tifanny}`,
            color: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px !important',
            fontSize: '14px',
        },
    },
    title: {
        fontFamily: 'Inter-Regular',
        fontSize: '48px',
        lineHeight: '58px',
        fontWeight: '400',
        color: `${colors.outerSpace}`,
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '24px',
            width: '80%'
        },
    },
    description: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '400',
        color: `${colors.outerSpace}`,
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },

    textField: {
        '& .MuiFormControl-root': {
            width: '50%',
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: '12px',
        },
        "& .MuiOutlinedInput-root": {
            color: `${colors.outerSpace}`,
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',
            "& fieldset": {
                borderColor: `${colors.outerSpace}`,
                borderRadius: '12px',
                color: `${colors.outerSpace}`,
            },
            "&:hover fieldset": {
                borderColor: `${colors.tifanny}`,
                color: `${colors.white}`,
            },
            "&.Mui-focused fieldset": {
                borderColor: `${colors.tifanny}`,
                color: `${colors.white}`,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        "& .MuiInputLabel-root": {
            color: `${colors.outerSpace}`,
            opacity: '0.8',
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',

            "&.Mui-focused": {
                color: `${colors.tifanny}`,
            },

            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
    },
    inputWrapper: {
        width: '100%',
        display: 'flex',
        gap: '20px',
        '& .MuiFormControl-root': {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '16px',
        },
    },
    subTitle: {
        fontFamily: 'Inter-Regular',
        fontWeight: '400',
        fontSize: '32px',
        lineHeight: '39px',
        color: `${colors.outerSpace}`,
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '22px',
        },
    },
    socialIcon: {
        [theme.breakpoints.down('sm')]: {
            width: '16px',
            height: '16px',
        },
    },
}));

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Введите имя'),
    phoneNumber: Yup.string()
        .matches(/^\d{10}$/, 'Введите корректный номер телефона (10 цифр)')
        .required('Введите номер телефона'),
});

const ContactModal = ({buttonText, titleText, width, padding}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [name] = useState('');
    const [phone] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        // Добавьте здесь логику отправки формы
        console.log('Отправлено: ', {name, phone});
        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            phoneNumber: '',
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
        },
    });

    return (
        <div style={{ width: width }}>
            <Button className={classes.mainButton} onClick={handleOpen} style={{ width: width, padding: padding }}>
                {buttonText || 'Открыть Модалку'}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    {/* Добавляем кнопку "крестик" */}
                    <Box>
                        <Box>
                            <Typography variant="h2" id="simple-modal-title" className={classes.title}>
                                {titleText || 'Записатися на прийом'}
                            </Typography>
                            <CloseIcon className={classes.closeButton} onClick={handleClose}/>
                        </Box>
                        <Typography variant="body1" id="simple-modal-description" className={classes.description}>
                            Ми зв'яжемося з вами найближчим часом, відповімо на запитання, узгодимо дату і час візиту.
                        </Typography>
                        <form className={classes.form}>
                            <Box className={classes.inputWrapper}>
                                <TextField
                                    label="Ваше ім’я*"
                                    name="firstName"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                                <TextField
                                    label="Телефон*"
                                    name="phoneNumber"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                />
                            </Box>
                            <div className={classes.buttonContainer}>
                                <Button className={classes.mainButton} onClick={handleSubmit} style={{width: '100%'}}>
                                    Відправити
                                </Button>
                            </div>
                        </form>
                    </Box>
                    <Box>
                        <Typography variant="h3" className={classes.subTitle}>
                            Записатись на прийом можна також через:
                        </Typography>
                        <div className={classes.socialButtons}>
                            <Button className={classes.socialButton}
                                    onClick={() => window.open('https://www.instagram.com/', '_blank')}>
                                <Icon
                                    component={InstagramIcon}
                                    className={classes.socialIcon}
                                    src={InstagramIcon}
                                />
                                Instagram
                            </Button>
                            <Button className={classes.socialButton}
                                    onClick={() => window.open('https://web.telegram.org/', '_blank')}>
                                <Icon
                                    component={TelegramIcon}
                                    className={classes.socialIcon}
                                    src={TelegramIcon}
                                />
                                Telegram
                            </Button>
                        </div>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default ContactModal;
