import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {createTheme, CssBaseline, ThemeProvider} from "@material-ui/core";
import MainPage from "./components/pages/Main";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import About from "./components/pages/About";
import Stuff from "./components/pages/Stuff";
import ReviewsPage from "./components/pages/Feedbacks";
import Services from "./components/pages/Services";
import ServiceDetail from "./components/UI/ServiceDetails";
import NotFoundPage from "./components/pages/404";
import CarerPage from "./components/pages/Carer";
import Blog from "./components/pages/Blog";
import PostPage from "./components/pages/Blog/PostPage";
import GalleryPage from "./components/pages/Gallery";
import StuffPage from "./components/pages/Stuff/StuffPage";
import {FloatingButtonProvider} from "./components/pages/ModalWindow/FloatingModal/FloatingButtonContext";
import {CSSTransition} from "react-transition-group";

import './index.css';

const theme = createTheme();

const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <CssBaseline/>
                <CSSTransition
                    in={isLoading}
                    timeout={300}
                    classNames="loader"
                    unmountOnExit
                >
                    <div className="loader-wrapper">
                        <div className="loader"></div>
                    </div>
                </CSSTransition>
                {!isLoading && (
                    <FloatingButtonProvider>
                        <Header/>
                        <Routes>
                            <Route path="/" element={<MainPage/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/about/feedbacks" element={<ReviewsPage/>}/>
                            <Route path="/about/blog" element={<Blog/>}/>
                            <Route path="/about/blog/:postSlug" element={<PostPage/>}/>
                            <Route path="/about/gallery" element={<GalleryPage/>}/>
                            <Route path="/stuff" element={<Stuff/>}/>
                            <Route path="/stuff/:stuffName" element={<StuffPage/>}/>
                            <Route path="/services" element={<Services/>}/>
                            <Route path="/services/:serviceName" element={<ServiceDetail/>}/>
                            <Route path="/carer" element={<CarerPage/>}/>
                            <Route path="*" element={<NotFoundPage/>}/>
                        </Routes>
                        <Footer/>
                    </FloatingButtonProvider>
                )}
            </Router>
        </ThemeProvider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
