import React, {useState} from 'react';
import PageTitle from "../../../UI/H1";
import Description from "../../../UI/DescriptionText";
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import StyledButton from "../../../UI/StyledButton";
import { useEffect } from 'react';
import FirstSpecialist from '../../../../assets/img/stuff/IMG_0740.jpeg';
import SecondSpecialist from '../../../../assets/img/stuff/IMG_0737.jpeg';
import ThirdSpecialist from '../../../../assets/img/stuff/IMG_0750.jpeg';
import FourthSpecialist from '../../../../assets/img/stuff/IMG_0787.jpeg';
import FifthSpecialist from '../../../../assets/img/service/aqua-preview.jpg';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: '100%',
        display: 'flex',
        gap: '20px',
        aspectRatio: '2/1',
        justifyContent: 'center',
        margin: '32px 0 32px 0',
        [theme.breakpoints.down('sm')]: {
            gap: '10px',
            margin: '20px 0 20px 0',
        },
    },
    roundedImage: {
        width: '48%',
        objectFit: 'cover',
        borderRadius: '36px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '12px',
        },
    },
    buttonWrapper: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

function WorkingStuff() {
    const classes = useStyles();
    const stuffImages = [FirstSpecialist, SecondSpecialist, ThirdSpecialist, FourthSpecialist, FifthSpecialist];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % stuffImages.length);
        }, 6000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [currentImageIndex]);

    return (
        <>
            <PageTitle>
                Робочий персонал
            </PageTitle>
            <Description>
                Кожен з наших спеціалістів має безцінний багаторічний досвід роботи з дітьми різної етіології захворювань нервової системи і власну авторську, перевірену роками, програму корекції та компенсації психічних порушень.
            </Description>

            <Box className={classes.imageContainer}>
                {/*<img*/}
                {/*    src={BannerHistoryTest}*/}
                {/*    alt="BannerHistoryTest"*/}
                {/*    className={classes.roundedImage}*/}
                {/*/>*/}
                {/*<img*/}
                {/*    src={BannerHistoryTest}*/}
                {/*    alt="BannerHistoryTest"*/}
                {/*    className={classes.roundedImage}*/}
                {/*/>*/}

                {[...Array(2)].map((_, index) => {
                    const projectIndex = (currentImageIndex + index) % stuffImages.length;
                    const isAnimatedOut = index === 0 && projectIndex !== currentImageIndex;
                    const projectCardClass = isAnimatedOut ? classes.animateOut : '';

                    return (
                        <img
                            src={stuffImages[projectIndex]}
                            alt="License"
                            className={`${classes.roundedImage} ${projectCardClass}`}
                            loading={'lazy'}
                        />


                    );
                })}
            </Box>
            <Box className={classes.buttonWrapper}>
                <StyledButton text="Дізнайтеся про кар'єру в клініці" variant="defaultButton" width='100%' to="/carer"/>
            </Box>
        </>
    );
}

export default WorkingStuff;