import React from 'react';
import PageTitle from "../../../UI/H1";
import Description from "../../../UI/DescriptionText";
import { makeStyles } from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {useEffect} from 'react';
import {useState} from 'react';
import FirstLicense from '../../../../assets/img/licenses/s1.jpg';
import SecondLicense from '../../../../assets/img/licenses/s2.jpg';
import ThirdLicense from '../../../../assets/img/licenses/s3.jpg';
import FourthLicense from '../../../../assets/img/licenses/s4.jpg';
import FifthLicense from '../../../../assets/img/licenses/s5.jpg';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: '100%',
        display: 'flex',
        gap: '20px',
        aspectRatio: '2/1',
        justifyContent: 'center',
        marginTop: '32px',
        animation: '$fadeOut 0.7s ease',
        transition: 'all 0.5s ease',
        '-webkit-transition': 'all 0.5s ease',
        '-moz-transition': 'all 0.5s ease',
        '-o-transition': 'all 0.5s ease',
        [theme.breakpoints.down('sm')]: {
            gap: '10px',
            marginTop: '20px',
        },
    },
    animateOut: {
        animation: '$fadeOut 0.7s ease',
    },
    roundedImage: {
        width: '48%',
        objectFit: 'cover',
        borderRadius: '36px',
        animation: '$fadeOut 0.7s ease',
        transition: 'all 0.5s ease',
        '-webkit-transition': 'all 0.5s ease',
        '-moz-transition': 'all 0.5s ease',
        '-o-transition': 'all 0.5s ease',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '12px',
        },
    },
    '@keyframes fadeOut': {
        '0%': {
            opacity: '0',
        },
        '100%': {
            opacity: '1',
        }
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: '1',
        },
        '100%': {
            opacity: '0',
        }
    }
}));

function Licenses() {
    const classes = useStyles();
    const licenseImages = [FirstLicense, SecondLicense, ThirdLicense, FourthLicense, FifthLicense];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % licenseImages.length);
        }, 7000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [currentImageIndex]);


    return (
        <>
            <PageTitle>Ліцензії</PageTitle>
            <Description>
                Наш центр має ліцензію на надання медичних послуг, а також сертифікати на використання обладнання та методик, які використовуються в роботі.
            </Description>

            <Box className={classes.imageContainer}>
                {[...Array(2)].map((_, index) => {
                    const projectIndex = (currentImageIndex + index) % licenseImages.length;
                    const isAnimatedOut = index === 0 && projectIndex !== currentImageIndex;
                    const projectCardClass = isAnimatedOut ? classes.animateOut : '';

                    return (
                        <img
                            src={licenseImages[projectIndex]}
                            alt="License"
                            className={`${classes.roundedImage} ${projectCardClass}`}
                            onLoad={() => console.log('Image loaded')}
                        />


                    );
                })}
            </Box>
        </>
    );
}

export default Licenses;


